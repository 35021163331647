import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { ErrorViewRootContainer } from '../../styles/styled-components/containers';
import { ErrorViewContent, ErrorViewMainContent } from '../../styles/styled-components/misc';
import { ErrorViewText } from '../../styles/styled-components/text';

type NoContentProps = {
	inValidQrCodeIdentifier?: boolean;
	hasNoContent?: boolean;
};

export function NoContent({ inValidQrCodeIdentifier, hasNoContent }: NoContentProps) {
	const { t } = useTypedTranslation();

	return (
		<ErrorViewRootContainer>
			<ErrorViewMainContent>
				<ErrorViewContent>
					<ErrorViewText>
						{inValidQrCodeIdentifier && t('qr-code-identifier-error') + window.localStorage.getItem('identifier')}
					</ErrorViewText>
					<QrCodeScannerIcon sx={{ fontSize: 195, fill: '#00195F', strokeWidth: 1.3, stroke: '#fff' }} />
					{hasNoContent && <ErrorViewText>{t('no-content.no-data')}</ErrorViewText>}
					<ErrorViewText>{t('no-content.main-text')}</ErrorViewText>
				</ErrorViewContent>
			</ErrorViewMainContent>
		</ErrorViewRootContainer>
	);
}
