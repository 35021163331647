import { useEffect } from 'react';
import { setQuestionnaire, useSession } from '../stores/SessionStore';
import { useLazyQuery } from '@apollo/client';
import { QuestionnaireQueryResponse } from '../models/queries/QueryResponses';
import { GET_QUESTIONNAIRE } from '../graphql/queries';

export function useGetQuestionnaire() {
	const { dispatch } = useSession();
	const [getQuestionnaire] = useLazyQuery<QuestionnaireQueryResponse>(GET_QUESTIONNAIRE);

	useEffect(() => {
		async function fetchQuestionnaire() {
			const { data } = await getQuestionnaire();
			if (data) {
				dispatch(setQuestionnaire(data.questionnaire));
			}
		}
		fetchQuestionnaire();
	}, [dispatch, getQuestionnaire]);
}
