import { AnalyticsService } from './AnalyticsService';
import { AnalyticsEvent } from './AnalyticsEvent';

declare let gtag: Function;

export class GoogleAnalyticsService implements AnalyticsService {
	trackEvent(event: AnalyticsEvent): void {
		// Check if gtag is defined before calling it
		try {
			gtag('event', event.type, {
				qrCode: event.qrCode,
				locale: event.locale,
				questionsAnswered: event.questionsAnswered,
				event_data: event.data
			});
		} catch (e) {
			console.warn('Failed tracking with Google analytics, this is potentially due to blockers', event, e);
		}
	}
}
