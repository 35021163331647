import styled from 'styled-components';
import { MobileProps } from '../interfaces';

export const StyledHeader = styled('div')<MobileProps>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	background: #fff;
	width: 100%;
	align-items: center;
	padding: ${props => (props.mobile ? '0.3rem' : '1rem 2rem')};
	border-bottom: ${props => (props.mobile ? '0.1rem solid #7F8BAC' : 'none')};
`;

export const Logo = styled('img')<MobileProps>`
	height: ${props => (props.palette === 'SOL' ? '6rem' : '2.5rem')};
	width: ${props => (props.palette === 'SOL' && !props.mobile ? '25rem' : 'initial')};
	margin-left: ${props => (props.palette === 'SOL' ? '0px' : '1rem')};
`;
