import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_LOCATION_PARAMETERS } from '../graphql/queries';
import { LocationParametersQueryResponse } from '../models/queries/QueryResponses';
import { setActivePalette, setLanguage, useSession } from '../stores/SessionStore';

export function useGetLocationThemeAndLocale(
	identifier: string | undefined,
	codeExists: boolean | undefined,
	locationId: string | undefined
) {
	const { dispatch } = useSession();
	const [getLocationParameters] = useLazyQuery<LocationParametersQueryResponse>(GET_LOCATION_PARAMETERS);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getLocationParametersImpl(locationId: string | null, identifier: string | null) {
			setLoading(true);
			const { data } = await getLocationParameters({ variables: { locationId: locationId, identifier: identifier } });
			if (data) {
				dispatch(setActivePalette(data.locationParameters.locationTheme));
				dispatch(setLanguage(data.locationParameters.locale));
			}
			setLoading(false);
		}
		if (codeExists && identifier) {
			getLocationParametersImpl(null, identifier);
		} else {
			if (locationId) {
				getLocationParametersImpl(locationId, null);
			}
		}
	}, [codeExists, identifier, locationId, dispatch, getLocationParameters]);

	return { loading };
}
