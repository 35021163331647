import ClearIcon from '@mui/icons-material/Clear';
import { useKeycloak } from '@react-keycloak/web';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { ErrorViewButton } from '../../styles/styled-components/buttons';
import { ErrorViewRootContainer } from '../../styles/styled-components/containers';
import { ErrorViewCircle, ErrorViewContent, ErrorViewMainContent } from '../../styles/styled-components/misc';
import { ErrorViewText } from '../../styles/styled-components/text';

export function PermissionDenied() {
	const { t } = useTypedTranslation();
	const { keycloak } = useKeycloak();

	return (
		<ErrorViewRootContainer>
			<ErrorViewMainContent>
				<ErrorViewContent>
					<ErrorViewCircle>
						<ClearIcon sx={{ fontSize: 120, fill: '#ff5a73', strokeWidth: 1, stroke: '#fff' }} />
					</ErrorViewCircle>
					<ErrorViewText>{t('permission_denied.title')}</ErrorViewText>
				</ErrorViewContent>
				<ErrorViewButton variant="contained" onClick={() => keycloak.logout()} size="large">
					{t('permission_denied.button')}
				</ErrorViewButton>
			</ErrorViewMainContent>
		</ErrorViewRootContainer>
	);
}
