/* <---- CONTAINER & BLOCK COMPONENTS */

import { Box } from '@mui/material';
import styled, { css } from 'styled-components';
import { freesiColors, solColors } from '../globalStyles';
import {
	ChartDataProps,
	GraphContainerProps,
	HeightProps,
	InfoMeasurementContainerProps,
	MobileProps,
	PaletteProps,
	QuestionContainerProps
} from '../interfaces';

export const flexColumn = css`
	display: flex;
	flex-direction: column;
`;

export const flexRow = css`
	display: flex;
	flex-direction: row;
`;

export const autoCompleteStyle = { width: '30rem' };

export const FlexContainer = styled('div')`
	${flexColumn}
	width: 100%;
	height: 100%;
`;

export const MeasurementsViewContainer = styled('div')<MobileProps>`
	height: fit-content;
	display: flex;
	flex-direction: ${props => (props.mobile ? 'column' : 'row')};
	gap: 1rem;
	flex-wrap: wrap;
`;

export const InfoTvContainer = styled('div')<MobileProps>`
	display: flex;
	flex-direction: ${props => (props.mobile ? 'column' : 'row')};
	width: 100%;
	height: 100%;
`;

export const DashContainer = styled('div')`
	${flexColumn}
	height: 100%;
	width: 100%;
	align-item: center;
	gap: 0.5rem;
`;

export const SatisfactionValueWrapper = styled('div')<MobileProps>`
	${flexColumn}
	align-items: center;
	width: ${props => (!props.mobile ? '60%' : 'auto')};
	justify-content: ${props => (!props.mobile ? 'space-evenly' : 'initial')};
`;

export const InfoMeasurementContainer = styled('div')<InfoMeasurementContainerProps>`
	${flexColumn}
	flex: ${props => (props.fullWidth ? 3 : 6)};
	height: 100%;
`;

export const MobileTemperatureContainer = styled('div')<PaletteProps>`
	flex: 1;
	background: ${props => (props.palette === 'SOL' ? solColors.solDarkYellow : freesiColors.iisyBlue)};
	padding: 1.5rem;
`;

export const ColumnContainer = styled('div')`
	${flexColumn}
	gap: 1rem;
`;

export const SatisfactionRootContainer = styled('div')`
	${flexRow}
	flex: 1;
	max-width: 100%;
	justify-content: space-between;
	gap: 1rem;
	align-items: center;
`;

export const SatisfactionContainer = styled('div')<PaletteProps>`
	${flexColumn}
	flex: 1;
	align-items: center;
	height: 100%;
	border-radius: 0.5rem;
	background: ${props => (props.palette === 'SOL' ? '#FCEBC2' : '#f5f6ff')};
	padding: 2rem;
	gap: 2rem;
`;

export const QuestionnaireContainer = styled('div')`
	${flexColumn}
	width: 100%;
	height: 100%;
	gap: 0.5em;
	align-items: center;
`;

export const QuestionContainer = styled('div')<QuestionContainerProps>`
	${flexColumn}
	flex: 1;
	width: 100%;
	background: ${props => props.background};
	border-radius: ${props => (props.hasBorders ? 'none' : '5px')};
	border-top: ${props => (props.hasBorders ? '1px solid rgba(5, 25, 86, .25)' : 'none')};
	border-bottom: ${props => (props.hasBorders ? '1px solid rgba(5, 25, 86, .25)' : 'none')};
	margin-top: ${props => (props.marginTop ? props.marginTop : '5px')};
	justify-content: center;
	align-items: center;
	padding: 1rem;
`;

export const GraphContainer = styled('div')<GraphContainerProps>`
	${flexColumn}
	border-radius: 0.5rem;
	padding: 1rem;
	gap: 2rem;
	background: ${props => props.backgroundColor};
	flex: 1;
`;

export const GraphSatisfactionContainer = styled('div')`
	${flexColumn}
	width: 100%;
	min-height: 22rem;
	gap: 2rem;
	margin-top: 1rem;
	border: 1px solid #d7d7e3;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 0.5rem;
	padding: 0.5rem;
	justify-content: center;
`;

export const ToolbarContainer = styled('div')`
	${flexRow}
	width: 100%;
	align-items: center;
	justify-content: center;
	gap: 1.5rem;
	padding: 3rem;
	background-color: #f5f5f8;
`;

export const PagesContainer = styled('div')`
	${flexRow}
	align-items: center;
	justify-content: center;
	background-color: #f5f5f8;
	gap: 1rem;
	padding: 2rem;
`;

export const InstallViewContainer = styled('div')`
	flex: 1;
`;

export const SelectionContainer = styled('div')`
	${flexColumn}
	align-items: center;
	gap: 3rem;
	padding: 1rem;
`;

export const ButtonGroupContainer = styled('div')`
	${flexColumn}
	align-items: center;
	justify-content: center;
	height: 100%;
`;

export const SummaryContainer = styled('div')`
	${flexColumn}
	flex: 1;
	padding-top: 1rem;
`;

export const EndContainer = styled('div')`
	${flexColumn}
	justify-content: center;
	align-items: center;
`;

export const EndTextContainer = styled('div')`
	${flexColumn}
	height: 80vh;
	justify-content: center;
	align-items: center;
`;

export const ErrorViewRootContainer = styled('div')`
	${flexColumn}
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
`;

export const NoUserSatisfactionContainer = styled('div')`
	${flexColumn}
	align-items: center;
	justify-content: center;
	gap: 2rem;
`;

export const LastWeekContainer = styled('div')`
	${flexColumn}
	width: 100%;
	min-height: 25rem;
	border: 1px solid #d7d7e3;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	border-radius: 0.5rem;
	padding: 0.5rem;
`;

export const ChartDataContainer = styled('div')<ChartDataProps>`
	display: block;
	margin: 0 auto;
	justify-content: center;
	height: 80%;
	width: ${props =>
		props.viewportWidth > 1500 && props.type === 'TEMP'
			? '80rem'
			: props.viewportWidth > 1500 && props.type === 'CO2'
			? '70rem'
			: props.viewportWidth < 1500 && props.viewportWidth > 768 && props.type === 'TEMP'
			? '60rem'
			: props.viewportWidth < 1500 && props.viewportWidth > 768 && props.type === 'CO2'
			? '55rem'
			: props.viewportWidth > 768 && props.type === 'TEMP'
			? '43rem'
			: '39rem'};
`;

export const NotificationBlock = styled('div')`
	${flexColumn}
	background: #dcc2f7;
	width: 100%;
	height: 60px;
	padding: 0.5rem;
	margin-bottom: 0.5rem;
`;

export const MobileDashBlock = styled('div')<MobileProps>`
	background: #00195a;
	height: ${props =>
		props.mobile && props.type === 'temperature'
			? '1.2rem'
			: props.mobile && props.type === 'measurement'
			? '20%'
			: props.mobile && props.type === 'measurementHalf'
			? '50%'
			: '100%'};
	min-height: ${props =>
		props.mobile && props.type === 'measurement'
			? 'calc((var(--app-height) - (6.2rem + 12rem)) / 3)'
			: props.mobile && props.type === 'measurementTemp' && 'calc((var(--app-height) - 6.2rem) / 3)'};
	border-radius: 5px;
	padding: ${props => (props.mobile ? '10px' : '20px')};
`;

export const FullSizeBlock = styled('div')<PaletteProps>`
	${flexColumn}
	background: ${props => (props.palette === 'SOL' ? '#FFC72C' : '#00195a')};
	flex: 4;
	justify-content: center;
`;

export const MediumSizeBlock = styled('div')`
	background: #00195a;
	width: 50%;
	height: 50%;
`;

export const SummaryContentBlock = styled('div')<HeightProps>`
	${flexColumn}
	height: ${props => props.height};
`;

export const PinCodeContainer = styled(Box)`
	${flexColumn}
	height: 40vh;
	width: 90vw;
	margin-top: 30vh;
	margin-left: 5vw;
	justify-content: center;
	align-items: center;
	background-color: #f5f5f8;
	border-radius: 20px;
`;
export const InnerPinCodeContainer = styled(Box)`
	${flexColumn}
	height: 15vh;
	width: 90vw;
	justify-content: center;
	align-items: center;
`;

export const GranlundContainer = styled('div')`
	${flexColumn}
	align-items: center;
	justify-content: center;
	height: 100%;
	width: 100%;
`;

export const ApplicationWrapperContainer = styled.div`
	overflow-y: auto;
	flex: 1;
	padding: 1rem;
	overflow-x: auto;
`;

/* CONTAINER & BLOCK COMPONENTS ----> */
