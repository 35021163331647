import { ScoreType } from '../../models/Feelis';
import vhappy from '../../images/bear-vhappy.svg';
import happy from '../../images/bear-happy.svg';
import neutral from '../../images/bear-neutral.svg';
import sad from '../../images/bear-sad.svg';
import vsad from '../../images/bear-vsad.svg';
import smileyvsad from '../../images/smiley-vsad.svg';
import smileysad from '../../images/smiley-sad.svg';
import smileyneutral from '../../images/smiley-neutral.svg';
import smileyhappy from '../../images/smiley-happy.svg';
import smileyvhappy from '../../images/smiley-vhappy.svg';
import { ActivePalette } from '../../models/context/Session';

/*
 * Particulate and TVOC has range of two (0: normal, 1: elevated)
 * Ventilation and Satisfaction has range of five (1: bad, 2: satisfactory, 3: moderate, 4: good, 5: excellent)
 */
export function scoreMapper(score: number, type: ScoreType) {
	if (score >= 0 && score <= 5) {
		const texts = [
			'core.normal',
			type === ScoreType.PARTICULATE || type === ScoreType.TVOC ? 'core.elevated' : 'core.bad',
			'core.satisfactory',
			'core.moderate',
			'core.good',
			'core.excellent'
		];
		return texts[score];
	}
	throw new Error(`Invalid score number of type ${type}: ${score}`);
}

export function iconMapper(score: number, palette: string) {
	const icons =
		palette === ActivePalette.SOL
			? [smileyvsad, smileysad, smileyneutral, smileyhappy, smileyvhappy]
			: [vsad, sad, neutral, happy, vhappy];
	if (score >= 1 && score <= 5) {
		return icons[score - 1];
	}
	throw new Error(`Invalid score number for user satisfaction: ${score}`);
}
