import { isNil } from 'lodash';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { AverageUserSatisfaction } from '../../../models/queries/QueryResponses';
import { useSession } from '../../../stores/SessionStore';
import { ColumnContainer, SatisfactionContainer } from '../../../styles/styled-components/containers';
import { Heading4, SatisfactionTileText } from '../../../styles/styled-components/text';
import { FeedbackTile } from './FeedbackTile';
import { SatisfactionScore } from './SatisfactionScore';

interface Props {
	data: AverageUserSatisfaction | null;
	timeRange: string;
}

export function SatisfactionTile({ data, timeRange }: Props) {
	const { t } = useTypedTranslation();
	const { state } = useSession();

	if (isNil(data)) return null;
	return (
		<SatisfactionContainer palette={state.activePalette}>
			<ColumnContainer>
				<Heading4 palette={state.activePalette}>{t('user-satisfaction.root')}</Heading4>
				<SatisfactionTileText palette={state.activePalette}>{timeRange}</SatisfactionTileText>
			</ColumnContainer>
			{typeof data.value === 'number' && (
				<SatisfactionScore activePalette={state.activePalette} data={data.value}></SatisfactionScore>
			)}
			{typeof data.lastNegFeedback === 'number' && (
				<FeedbackTile
					activePalette={state.activePalette}
					isDesktop={state.isDesktop}
					lastNegFeedback={data.lastNegFeedback}></FeedbackTile>
			)}
		</SatisfactionContainer>
	);
}
