import { useLazyQuery } from '@apollo/client';
import { BuildingParamsResponse } from '../models/queries/QueryResponses';
import { GET_LOCATION_PARAMS } from '../graphql/queries';
import { useEffect } from 'react';

export function useGetLocationTypes() {
	const [getLocationParams, { data }] = useLazyQuery<BuildingParamsResponse>(GET_LOCATION_PARAMS);

	useEffect(() => {
		getLocationParams();
	}, [getLocationParams]);

	return { locationTypes: data?.buildingTypeParametersForLocation.values };
}
