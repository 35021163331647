import { gql } from '@apollo/client';

export const SEND_ANSWER = gql`
	mutation SendAnswer($premiseId: ID!, $questionId: ID!, $qrCodeId: ID!, $value: Int!, $sessionId: ID!) {
		sendAnswer(
			premiseId: $premiseId
			questionId: $questionId
			qrCodeId: $qrCodeId
			value: $value
			sessionId: $sessionId
		)
	}
`;

export const SAVE_QR_CODE = gql`
	mutation saveNewQrCode($request: SaveQrRequest!) {
		saveQrCode(request: $request)
	}
`;
