import { useState } from 'react';
import {
	setDisableState,
	setNewLocation,
	setNewPremise,
	setSelectedLocation,
	setSelectedPremise,
	useInstall
} from '../stores/InstallStore';
import { useSaveQrCode } from './useSaveQrCode';
import { LoginResponse } from '../auth/useLogin';
import { ActivePage } from '../models/Feelis';

export function useHandlePaging(userData: LoginResponse | null) {
	const { dispatch } = useInstall();
	const { responseCode, saveQrCode } = useSaveQrCode();

	const [pageNumber, setPageNumber] = useState<number>(1);
	const [locationInCreation, setLocationInCreation] = useState<boolean>(false);
	const [premiseInCreation, setPremiseInCreation] = useState<boolean>(false);

	function handleSave() {
		saveQrCode(userData);
		nextPage();
	}

	function handleCancel() {
		if (pageNumber === 1) {
			dispatch(setNewLocation(undefined));
			setLocationInCreation(false);
			setPremiseInCreation(false);
		}
		if (pageNumber === 2) {
			dispatch(setNewPremise(undefined));
			setPremiseInCreation(false);
			previousPage();
		}
	}

	function previousPage() {
		dispatch(setSelectedLocation(undefined));
		dispatch(setSelectedPremise(undefined));
		setPageNumber(prevState => prevState - 1);
	}

	function nextPage() {
		setPageNumber(prevState => prevState + 1);
		dispatch(setDisableState(true));
	}

	const activePage: ActivePage = pages[pageNumber - 1];

	return {
		pageNumber,
		activePage,
		setLocationInCreation,
		setPremiseInCreation,
		locationInCreation,
		premiseInCreation,
		handleSave,
		handleCancel,
		nextPage,
		previousPage,
		responseCode
	};
}

export const pages = [
	{
		id: 1,
		title: 'selection.building.title',
		question: 'selection.building.notfound',
		new: 'selection.building.new',
		formTitle: 'selection.building.form-title'
	},
	{
		id: 2,
		title: 'selection.premise.title',
		question: 'selection.premise.notfound',
		new: 'selection.premise.new',
		namePrefix: 'selection.premise.name-prefix',
		nameSuffix: 'selection.premise.name-suffix' //TODO: Käännökset
	},
	{
		id: 3,
		title: 'selection.summary.title',
		question: 'selection.summary.title',
		new: 'selection.summary.title'
	}
];
