import { Autocomplete, TextField } from '@mui/material';
import { useCreatePremise } from '../../hooks/useCreatePremise';
import { useGetPremiseTypes } from '../../hooks/useGetPremiseTypes';
import { usePremiseInCreation } from '../../hooks/usePremiseInCreation';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { useWithFallbackContent } from '../../hooks/useWithFallbackContent';
import { ActivePage } from '../../models/Feelis';
import { ParameterValue } from '../../models/context/Install';
import { SelectionContainer, autoCompleteStyle } from '../../styles/styled-components/containers';
import { SelectionTitle } from '../../styles/styled-components/text';
import { makeFloorNumberArray } from '../../utils/helperFunctions';
import { AnimatedContainer } from './AnimatedContainer';

type CreatePremiseProps = {
	page: ActivePage;
	setPremiseInCreation: (bool: boolean) => void;
};

export function CreatePremise({ page, setPremiseInCreation }: CreatePremiseProps) {
	const { t, unsafeTranslation } = useTypedTranslation();
	const withFallback = useWithFallbackContent();
	usePremiseInCreation(setPremiseInCreation);
	const { premiseTypes } = useGetPremiseTypes();
	const { createdPremise, setCreatedPremise } = useCreatePremise();

	function handleTypesChangePremise(value: ParameterValue | null) {
		if (value) setCreatedPremise({ ...createdPremise, type: value });
		else console.error('Could not parse the value correctly. Please reload the page and try again!');
	}

	function handleFloorChange(value: number | null) {
		if (typeof value === 'number') setCreatedPremise({ ...createdPremise, floor: value });
		else console.error('Could not parse the value correctly. Please reload the page and try again!');
	}

	const handleTextChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setCreatedPremise({
			...createdPremise,
			[e.target.name]: e.target.value
		});
	};

	return (
		<AnimatedContainer>
			<SelectionContainer>
				<SelectionTitle>{unsafeTranslation(page.new)}</SelectionTitle>
				<Autocomplete
					options={premiseTypes || []}
					getOptionLabel={option => withFallback(option.name) ?? option.id}
					onChange={(event, newValue) => handleTypesChangePremise(newValue)}
					sx={autoCompleteStyle}
					renderInput={params => <TextField {...params} label={t('location.type')} variant="standard" />}
				/>
				<Autocomplete
					defaultValue={1}
					options={makeFloorNumberArray() || []}
					getOptionLabel={option => option.toString()}
					onChange={(event, newValue) => handleFloorChange(newValue)}
					sx={autoCompleteStyle}
					isOptionEqualToValue={(option: number, value: number) => option === value}
					renderInput={params => <TextField {...params} label={t('location.floor')} variant="standard" />}
				/>
				{page.namePrefix && page.nameSuffix && (
					<>
						<TextField
							name="namePrefix"
							label={unsafeTranslation(page.namePrefix)}
							variant="outlined"
							type="text"
							onChange={handleTextChange}
							sx={autoCompleteStyle}
						/>
						<TextField
							name="nameSuffix"
							label={unsafeTranslation(page.nameSuffix)}
							variant="outlined"
							type="text"
							onChange={handleTextChange}
							sx={autoCompleteStyle}
						/>
					</>
				)}
			</SelectionContainer>
		</AnimatedContainer>
	);
}
