import { ExternalServices } from './queries/QueryResponses';

export enum ServiceRequestType {
	GranlundManager = 'granlund-manager-service-request'
}

export const supportedServices: string[] = [ServiceRequestType.GranlundManager];

export function isServiceRequestEnabled(services?: ExternalServices) {
	if (!services) return false;
	return services.some(svc => supportedServices.includes(svc.name));
}
