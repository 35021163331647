import { isEmpty } from 'lodash';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ActivePalette } from '../../../models/context/Session';
import { MeasurementData, Question, QuestionPercentage } from '../../../models/queries/QueryResponses';
import { useSession } from '../../../stores/SessionStore';
import { freesiColors, solColors } from '../../../styles/globalStyles';
import {
	GraphContainer,
	GraphSatisfactionContainer,
	LastWeekContainer
} from '../../../styles/styled-components/containers';
import { GraphTileInfoBox } from '../../../styles/styled-components/misc';
import { GraphTileInfoRow } from '../../../styles/styled-components/rows';
import {
	GraphSatisfactionTitle,
	GraphTileMeasurementTitle,
	Heading3,
	LastWeek,
	Paragraph
} from '../../../styles/styled-components/text';
import { QuestionTile } from '../question/QuestionTile';
import { FeedbackTile } from '../satisfaction/FeedbackTile';
import { FeelisBarChart } from './FeelisBarChart';
import { useDataType } from './useDataType';

interface Props {
	question: Question | undefined;
	data: MeasurementData;
	questionInfo: QuestionPercentage;
}

export function GraphTile({ question, data, questionInfo }: Props) {
	const { t } = useTypedTranslation();
	const { state } = useSession();

	const { IconComponent, ChartComponent, title, background } = useDataType(data.type, state.activePalette);

	return (
		<GraphContainer backgroundColor={background}>
			<GraphTileInfoRow>
				{data.currentValue && (
					<GraphTileInfoBox>
						<Heading3 palette={state.activePalette}>
							{data.currentValue} <Heading3 palette={state.activePalette}>{data.unit}</Heading3>
						</Heading3>
						<Paragraph palette={state.activePalette}>{t('graph.last-measurement')}</Paragraph>
					</GraphTileInfoBox>
				)}
				<GraphTileMeasurementTitle palette={state.activePalette}>{t(title)}</GraphTileMeasurementTitle>
				<IconComponent
					fill={state.activePalette === ActivePalette.SOL ? solColors.solDarkYellow : freesiColors.iisyBlue}
					height="3rem"
				/>
			</GraphTileInfoRow>
			{data.measurements && !isEmpty(data.measurements) && (
				<LastWeekContainer>
					<LastWeek palette={state.activePalette}>{t('core.last-week')}</LastWeek>
					<ChartComponent data={data} activePalette={state.activePalette} isDesktop={state.isDesktop} />
				</LastWeekContainer>
			)}
			<GraphSatisfactionContainer>
				<GraphSatisfactionTitle palette={state.activePalette}>{t('user-satisfaction.root')}</GraphSatisfactionTitle>
				{questionInfo && questionInfo.percentages && (
					<FeelisBarChart questionInfo={questionInfo} activePalette={state.activePalette} dataType={data.type} />
				)}
				{questionInfo && typeof questionInfo.lastNegFeedback === 'number' && (
					<FeedbackTile
						activePalette={state.activePalette}
						isDesktop={state.isDesktop}
						lastNegFeedback={questionInfo.lastNegFeedback}></FeedbackTile>
				)}
			</GraphSatisfactionContainer>
			{question && <QuestionTile question={question} background="f5f6ff" hasBorders={false} />}
		</GraphContainer>
	);
}
