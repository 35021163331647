import { css } from 'styled-components';

/* <---- GLOBAL */
export const ellipsisText = css`
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

/* <---- TYPOGRAPHY */
export const bodyText__Mobile = css`
	font-size: 1.6rem; // 16px
	font-weight: 500;
`;

export const secondaryText__Mobile = css`
	font-size: 1.4rem; // 14px
	font-weight: 400;
`;

export const headingText__Mobile = css`
	font-size: 2rem; // 20px
	font-weight: 600;
`;

export const topicText__Mobile = css`
	font-size: 3rem; //30px
	font-weight: 500;
`;

/* TYPOGRAPHY ----> */

/* GLOBAL ---->*/

/* COLORS */

export const freesiColors = {
	iisyBorder: '#80889c69',
	iisyBlue: '#00195F',
	iisyYellow: '#F9FF5A',
	iisyGreen: '#5BF7BF',
	iisyRed: '#FF5A73',
	iisyPurple: '#C599F1'
};

/* SOL */

export const solColors = {
	solDarkYellow: '#FFC72C',
	solMediumYellow: '#FFD86C',
	solLightYellow: '#FCEBC2',
	solRed: '#E4002B',
	solLightBlack: '#262122',
	white: '#FFFFFF'
};

/*
ICON FILL (SOL LIGHT BLACK): 'invert(0%) sepia(5%) saturate(1163%) hue-rotate(176deg) brightness(0%) contrast(89%)';

*/
