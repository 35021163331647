import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { ReactComponent as DashboardIcon } from '../../images/dashboard.svg';
import { ReactComponent as FeedbackIcon } from '../../images/feedback.svg';
import { ReactComponent as MeasurementsIcon } from '../../images/measurement.svg';
import { ReactComponent as MessageIcon } from '../../images/message.svg';
import { ActivePalette } from '../../models/context/Session';
import { ActionView } from '../../models/enum/ActionView';
import { freesiColors, solColors } from '../../styles/globalStyles';
import { useAnalytics } from '../../analytics/useAnalytics';

type BottomNavigationBarProps = {
	activeView: ActionView;
	setActiveView: (activeView: ActionView) => void;
	activePalette: ActivePalette;
	isServiceRequestEnabled: boolean;
};

export function BottomNavigationBar({
	activePalette,
	activeView,
	setActiveView,
	isServiceRequestEnabled
}: BottomNavigationBarProps) {
	const { t } = useTypedTranslation();
	const analytics = useAnalytics();

	const iconColor = activePalette === 'SOL' ? solColors.white : freesiColors.iisyGreen;

	const handleNavigation = (event: React.SyntheticEvent, newValue: ActionView) => {
		setActiveView(newValue);
		analytics.trackTabChange(newValue.toString());
	};

	return (
		<div style={{ height: '8.5rem' }}>
			<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
				<BottomNavigation
					showLabels
					value={activeView}
					onChange={handleNavigation}
					sx={{
						background: activePalette === 'SOL' ? solColors.solLightBlack : freesiColors.iisyBlue,
						height: '8rem',
						'& .MuiBottomNavigationAction-label': {
							color: iconColor,
							fontSize: '1.1rem'
						},
						'& .MuiBottomNavigationAction-root': {
							maxWidth: '30rem',
							'& .Mui-selected': {
								fontSize: '1.5rem'
							}
						}
					}}>
					<BottomNavigationAction
						label="Dashboard"
						icon={<DashboardIcon fill={iconColor} width="3rem" height="3rem" />}
						value={ActionView.Dashboard}
					/>
					<BottomNavigationAction
						label={t('footer-navigation.measurements')}
						icon={<MeasurementsIcon fill={iconColor} width="3rem" height="3rem" />}
						value={ActionView.Measurements}
					/>
					<BottomNavigationAction
						label={t('footer-navigation.give-feedback')}
						icon={<FeedbackIcon fill={iconColor} width="3rem" height="3rem" />}
						value={ActionView.Questionnaire}
					/>
					{isServiceRequestEnabled && (
						<BottomNavigationAction
							label={t('footer-navigation.service-request')}
							icon={<MessageIcon fill={iconColor} width="3rem" height="3rem" />}
							value={ActionView.Servicerequest}
						/>
					)}
				</BottomNavigation>
			</Paper>
		</div>
	);
}
