import { MotionValue, useTransform, motion } from 'framer-motion';

type ProgeProps = {
	progress: MotionValue<number>;
	circleColor: MotionValue<string>;
	strokeColor: string;
	motionPath: string;
};

export function CircularProgress({ progress, strokeColor, circleColor, motionPath }: ProgeProps) {
	const circleLength = useTransform(progress, [0, 100], [0, 1]);
	const checkMarkLength = useTransform(progress, [0, 95, 100], [0, 0, 1]);

	return (
		<motion.svg xmlns="http://www.w3.org/2000/svg" width="120" height="120" viewBox="0 0 258 258">
			{/* Check mark  */}
			<motion.path
				transform="translate(75 75)"
				d={motionPath}
				fill="transparent"
				stroke={strokeColor}
				strokeWidth={8}
				style={{
					pathLength: checkMarkLength
				}}
			/>
			{/* Circle */}
			<motion.path
				d="M 130 6 C 198.483 6 254 61.517 254 130 C 254 198.483 198.483 254 130 254 C 61.517 254 6 198.483 6 130 C 6 61.517 61.517 6 130 6 Z"
				fill="transparent"
				strokeWidth="8"
				stroke={circleColor}
				style={{
					pathLength: circleLength
				}}
			/>
		</motion.svg>
	);
}
