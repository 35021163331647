import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ActivePalette } from '../../../models/context/Session';
import { QuarterColumn } from '../../../styles/styled-components/columns';
import { DayCounterCircle } from '../../../styles/styled-components/misc';
import { QuarterRow } from '../../../styles/styled-components/rows';
import { BigTitle, ColumnTitle, DayCounterNumber } from '../../../styles/styled-components/text';
import { backgroundConfig } from '../../../utils/helperFunctions';
import { ScoreConfig } from './ScoreTile';

type DesktopFeedbackScoreProps = {
	activePalette: ActivePalette;
	config: ScoreConfig;
	data: number;
};

export function DesktopFeedbackScore({ activePalette, config, data }: DesktopFeedbackScoreProps) {
	const { t, unsafeTranslation } = useTypedTranslation();

	return (
		<QuarterRow mobile={false} palette={activePalette} background={backgroundConfig(config.color)}>
			<QuarterColumn>
				<ColumnTitle mobile={false} palette={activePalette}>
					{unsafeTranslation(config.text)}
				</ColumnTitle>
			</QuarterColumn>
			<QuarterColumn centered>{data && <Counter dayCount={data} palette={activePalette} />}</QuarterColumn>
			<QuarterColumn centered>
				<BigTitle style={{ fontSize: '3rem', textAlign: 'center' }} palette={activePalette}>
					{t('user-satisfaction.no-data-info')}
				</BigTitle>
			</QuarterColumn>
		</QuarterRow>
	);
}

interface CounterProps {
	dayCount: number;
	palette: string;
}

function Counter({ dayCount, palette }: CounterProps) {
	return (
		<DayCounterCircle palette={palette}>
			<DayCounterNumber palette={palette}>{dayCount}</DayCounterNumber>
		</DayCounterCircle>
	);
}
