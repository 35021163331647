import { PagesContainer } from '../../styles/styled-components/containers';
import { PageCircle } from '../../styles/styled-components/misc';

interface Props {
	active: number | undefined;
	amount: number;
}

export function Pages({ active, amount }: Props) {
	let pages = [];
	for (let i = 0; i < amount; ++i) {
		pages.push(
			<PageCircle key={i} active={active === i + 1}>
				<span>{i + 1}</span>
			</PageCircle>
		);
	}

	return <PagesContainer>{pages}</PagesContainer>;
}
