import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import defaultLogo from '../../images/logo_with_bars.svg';
import solBigLogo from '../../images/sol_big_logo.svg';
import { ActivePalette } from '../../models/context/Session';
import { HeaderColumn } from '../../styles/styled-components/columns';
import { Logo, StyledHeader } from '../../styles/styled-components/header';
import { HeaderRow } from '../../styles/styled-components/rows';
import { IndoorAirTitle, LocationTitle } from '../../styles/styled-components/text';
import { LanguagePicker } from '../language-picker/LanguagePicker';
import solSmallLogo from './../../images/sol_small_logo.svg';

type HeaderProps = {
	activePalette: ActivePalette;
	isDesktop: boolean;
	locationName: string | undefined;
	premiseName?: string | undefined;
	showLanguagePicker: boolean;
};

export function Header({ activePalette, isDesktop, locationName, premiseName, showLanguagePicker }: HeaderProps) {
	const { t } = useTypedTranslation();

	const logoSource =
		activePalette === ActivePalette.FREESI
			? defaultLogo
			: activePalette === ActivePalette.SOL && isDesktop
			? solBigLogo
			: solSmallLogo;

	return (
		<StyledHeader mobile={!isDesktop}>
			<Logo mobile={!isDesktop} src={logoSource} palette={activePalette} />
			<HeaderRow>
				<HeaderColumn>
					<LocationTitle mobile={!isDesktop} palette={activePalette}>
						{locationName}
					</LocationTitle>
					<IndoorAirTitle mobile palette={activePalette}>
						{premiseName ?? t('core.indoor-air')}
					</IndoorAirTitle>
				</HeaderColumn>
				{showLanguagePicker && <LanguagePicker />}
			</HeaderRow>
		</StyledHeader>
	);
}
