import { AppRouter } from './AppRouter';
import { useTrackPageChange } from './analytics/useTrackPageChange';
import { useGetInfoFromPath } from './hooks/useGetInfoFromPath';

const App = () => {
	const { isBuildingQr } = useGetInfoFromPath();
	useTrackPageChange();

	return <AppRouter isBuildingQr={isBuildingQr} />;
};

export default App;
