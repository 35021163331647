import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ScoreType } from '../../../models/Feelis';
import { ActivePalette } from '../../../models/context/Session';
import { QuarterColumn } from '../../../styles/styled-components/columns';
import { ColumnIcon } from '../../../styles/styled-components/icons';
import { QuarterRow } from '../../../styles/styled-components/rows';
import { BigTitle, ColumnTitle } from '../../../styles/styled-components/text';
import { backgroundConfig } from '../../../utils/helperFunctions';
import { scoreMapper } from '../../../views/infotv/infoHelper';
import { ScoreConfig } from './ScoreTile';

type DesktopScoreProps = {
	activePalette: ActivePalette;
	config: ScoreConfig;
	data: number;
	type: ScoreType;
};

export function DesktopScore({ activePalette, config, data, type }: DesktopScoreProps) {
	const { unsafeTranslation } = useTypedTranslation();

	return (
		<QuarterRow mobile={false} palette={activePalette} background={backgroundConfig(config.color)}>
			<QuarterColumn>
				<ColumnTitle mobile={false} palette={activePalette}>
					{unsafeTranslation(config.text)}
				</ColumnTitle>
			</QuarterColumn>
			<QuarterColumn centered>{config.icon && <ColumnIcon src={config.icon} palette={activePalette} />}</QuarterColumn>
			<QuarterColumn centered>
				{typeof data === 'number' && (
					<BigTitle palette={activePalette}>{unsafeTranslation(scoreMapper(data, type))}</BigTitle>
				)}
			</QuarterColumn>
		</QuarterRow>
	);
}
