import { useCallback, useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { loginApi } from './loginApi';
import { useInstall } from '../stores/InstallStore';
import { setAuth } from '../stores/InstallStore';

export interface LoginResponse {
	userId: string;
	userLocale: string;
	hasPermission: boolean;
}

export const useLogin = () => {
	const { dispatch } = useInstall();
	const { keycloak } = useKeycloak();
	const accessToken = keycloak.token ?? null;

	const [userData, setUserData] = useState<LoginResponse | null>(null);

	const login = useCallback(async () => {
		if (!accessToken) return;
		try {
			const response = await loginApi.login(accessToken);
			if (response) {
				dispatch(setAuth(true));
				setUserData(response);
			} else {
				dispatch(setAuth(false));
			}
		} catch (error) {
			keycloak.logout();
			console.error(error);
		}
	}, [accessToken, keycloak, dispatch]);

	useEffect(() => {
		if (keycloak.authenticated && keycloak.token) login();
	}, [login, keycloak]);

	return { userData, keycloakAuthenticated: keycloak.authenticated };
};
