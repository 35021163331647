import { NotificationTile } from '../../components/tile/notification/NotificationTile';
import { QuestionTile } from '../../components/tile/question/QuestionTile';
import { SatisfactionTile } from '../../components/tile/satisfaction/SatisfactionTile';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { setNotification, useSession } from '../../stores/SessionStore';
import { DashContainer, SatisfactionRootContainer } from '../../styles/styled-components/containers';
import { MeasurementRow } from './MeasurementRow';

export function DashboardView() {
	const { t } = useTypedTranslation();
	const { state, dispatch } = useSession();

	const handleHide = () => {
		dispatch(setNotification(false));
	};

	if (!state.dashboard) return null;
	return (
		<DashContainer>
			{/* Notifications to Feelis-portal are not yet implemented */}
			{state.dashboard.notification && state.showNotification && <NotificationTile onHide={handleHide} />}
			<MeasurementRow temperature={state.dashboard.temperature} co2Score={state.dashboard.co2Score} />
			<QuestionTile
				background={state.activePalette === 'SOL' ? '#FCFCFC' : '#DEFDF2'}
				question={state.dashboard.question}
				hasBorders={state.activePalette === 'SOL' ? true : false}
			/>
			<SatisfactionRootContainer>
				<SatisfactionTile data={state.dashboard.feelisPercentageScoreAll} timeRange={t('user-satisfaction.all-time')} />
				<SatisfactionTile data={state.dashboard.feelisPercentageScoreMonth} timeRange={t('user-satisfaction.month')} />
			</SatisfactionRootContainer>
		</DashContainer>
	);
}
