import { createContext, useContext, useReducer } from 'react';
import {
	IInstallContext,
	InstallActionTypes,
	InstallActions,
	InstallContextState,
	Location,
	NewLocation,
	NewPremise,
	Premise,
	SetAuthenticated,
	SetDisableState,
	SetNewLocation,
	SetNewPremise,
	SetSelectedLocation,
	SetSelectedPremise
} from '../models/context/Install';
import { ContextProviderProps } from '../models/context/Session';

const initialState: InstallContextState = {
	authenticated: false,
	disableState: true,
	location: undefined,
	premise: undefined,
	newLocation: undefined,
	newPremise: undefined
};

const reducer = (previousState: InstallContextState, action: InstallActions) => {
	switch (action.type) {
		case InstallActionTypes.SET_AUTH:
			return { ...previousState, authenticated: action.authenticated };
		case InstallActionTypes.SET_DISABLE:
			return { ...previousState, disableState: action.disableState };
		case InstallActionTypes.SET_SELECTED_LOCATION:
			return { ...previousState, location: action.location };
		case InstallActionTypes.SET_SELECTED_PREMISE:
			return { ...previousState, premise: action.premise };

		case InstallActionTypes.SET_NEW_LOCATION:
			return { ...previousState, newLocation: action.newLocation };
		case InstallActionTypes.SET_NEW_PREMISE:
			return { ...previousState, newPremise: action.newPremise };

		default:
			throw new Error('Reducer error: unhandled action type');
	}
};

// helper functions to make dispatch calling simpler
// -------------------------------------------------
export const setAuth = (authenticated: boolean): SetAuthenticated => ({
	type: InstallActionTypes.SET_AUTH,
	authenticated: authenticated
});

export const setDisableState = (disableState: boolean): SetDisableState => ({
	type: InstallActionTypes.SET_DISABLE,
	disableState: disableState
});

export const setSelectedLocation = (location: Location | undefined): SetSelectedLocation => ({
	type: InstallActionTypes.SET_SELECTED_LOCATION,
	location: location
});

export const setSelectedPremise = (premise: Premise | undefined): SetSelectedPremise => ({
	type: InstallActionTypes.SET_SELECTED_PREMISE,
	premise: premise
});

export const setNewLocation = (newLocation: NewLocation | undefined): SetNewLocation => ({
	type: InstallActionTypes.SET_NEW_LOCATION,
	newLocation: newLocation
});

export const setNewPremise = (newPremise: NewPremise | undefined): SetNewPremise => ({
	type: InstallActionTypes.SET_NEW_PREMISE,
	newPremise: newPremise
});

// -------------------------------------------------

const InstallContext = createContext<IInstallContext>({
	state: initialState,
	dispatch: () => undefined
});

export const InstallContextProvider = ({ children }: ContextProviderProps) => {
	const [state, dispatch] = useReducer(reducer, initialState);
	const value = { state, dispatch };

	return <InstallContext.Provider value={value}>{children}</InstallContext.Provider>;
};

// Custom hook to throw a helpful error message if it's being called outside of InstallContextProvider
export const useInstall = () => {
	const context = useContext(InstallContext);
	if (context === undefined) {
		throw new Error('useSession must be used within a InstallContextProvider');
	}
	return context;
};
