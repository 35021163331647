import styled, { css } from 'styled-components';
import { DimensionProps, MobileProps, ScoreTileProps } from '../interfaces';

export const FlexRow = styled('div')<DimensionProps>`
	display: flex;
	width: ${props => (props.fullWidth ? '100%' : 'auto')};
`;

export const AlignCenterRow = styled('div')<DimensionProps>`
	display: flex;
	align-items: center;
	width: ${props => (props.fullWidth ? '100%' : 'auto')};
`;

export const CenteredRow = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	justify-content: space-evenly;
	width: 100%;
`;

export const SpaceEvenlyRow = styled('div')`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
`;

export const TitleRow = styled('div')`
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: flex-end;
`;

export const FeelisRow = styled('div')<MobileProps>`
	display: flex;
	flex-direction: column;
	width: 48.5%;
	height: 100%;
	padding: ${props => (props.mobile ? '1rem' : '2rem')};
	border-radius: 0.5rem;
`;

export const QuarterRow = styled('div')<ScoreTileProps>`
	display: ${props => (props.mobile ? 'initial' : 'flex')};
	flex: 1;
	padding: 1.5rem;
	border-radius: ${props => (props.fullWidth ? '0.5rem' : 0)};

	${({ palette, background }) =>
		palette === 'SOL' &&
		css`
			&:nth-of-type(1n) {
				background: ${background.sol.primary};
			}

			&:nth-of-type(2n) {
				background: ${background.sol.secondary};
			}
		`}

	${({ palette, background }) =>
		palette === 'FREESI' &&
		css`
			background: ${background.defaultColor};
		`}
`;

export const HalfRow = styled('div')<ScoreTileProps>`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: ${props => (props.singleRow ? '100%' : '50%')};
	@media (max-width: 1500px) {
		height: 50%;
	}
`;

export const ThirtyRow = styled('div')`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 30%;
`;

export const FourRow = styled('div')`
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: space-between;
`;

export const SatisfactionRow = styled('div')`
	display: flex;

	&:nth-child(2) {
		align-items: center;
	}

	&:nth-child(3) {
		justify-content: center;
	}
`;

export const GraphTileInfoRow = styled('div')`
	display: flex;
	flex-direction: row;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	flex: 0;
`;

export const GraphSatisfactionRow = styled('div')`
	display: flex;
	width: 100%;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	text-align: center;
	flex: 0;
`;

export const SelectionRow = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const SelectionButtonRow = styled('div')`
	display: flex;
	height: 25%;
`;

export const SelectionQuestionRow = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	margin-top: 2.5em;
	margin-bottom: 0.3em;
`;

export const SummaryRow = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	background-color: #f5f5f8;
	padding: 1.5rem 5rem;
	gap: 1rem;
`;

export const SummaryContentRow = styled('div')`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 1.2rem 5rem;
	width: 100%;
	gap: 2rem;
`;

export const HeaderRow = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	gap: 1.5rem;
	width: 65%;
	align-items: center;
`;

export const CheckboxRow = styled('div')`
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
`;
