import { useState } from 'react';
import { Selection } from '../../components/selection/Selection';
import { useGetPremises } from '../../hooks/useGetPremises';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { ActivePage, SelectedOption } from '../../models/Feelis';
import { setDisableState, setSelectedPremise, useInstall } from '../../stores/InstallStore';
import { ToolbarButton } from '../../styles/styled-components/buttons';
import { InstallViewContainer } from '../../styles/styled-components/containers';
import { SelectionRow } from '../../styles/styled-components/rows';
import { CreatePremise } from './CreatePremise';

type PremisePageProps = {
	page: ActivePage;
	setPremiseInCreation: (bool: boolean) => void;
	premiseInCreation: boolean;
	locationInCreation: boolean;
};

export function PremisePage({ page, setPremiseInCreation, premiseInCreation, locationInCreation }: PremisePageProps) {
	const { unsafeTranslation } = useTypedTranslation();
	const { dispatch, state } = useInstall();
	const { premises } = useGetPremises(state.location?.id);

	const [selection, setSelection] = useState<SelectedOption | null>(null);

	const handleSelect = (event: object, value: any, reason: any) => {
		if (reason === 'selectOption') {
			dispatch(setSelectedPremise({ id: value.id, name: value.name }));
			setSelection(value);
			dispatch(setDisableState(false));
		}
		if (reason === 'clear') {
			setSelection(null);
			dispatch(setDisableState(true));
		}
	};

	return (
		<InstallViewContainer>
			{!premiseInCreation && !locationInCreation && (
				<>
					<Selection data={premises} page={page} selection={selection} handleSelect={handleSelect}></Selection>
					{!selection && (
						<SelectionRow>
							<ToolbarButton onClick={() => setPremiseInCreation(true)}>{unsafeTranslation(page.new)}</ToolbarButton>
						</SelectionRow>
					)}
				</>
			)}
			{premiseInCreation && <CreatePremise page={page} setPremiseInCreation={setPremiseInCreation}></CreatePremise>}
		</InstallViewContainer>
	);
}
