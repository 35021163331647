import { Variants, motion } from 'framer-motion';
import { ReactNode } from 'react';

type AnimatedContainerProps = {
	children: ReactNode;
	delay?: number;
};

export function AnimatedContainer({ children, delay }: AnimatedContainerProps) {
	const variants: Variants = {
		hidden: { opacity: 0, y: 30 },
		visible: { opacity: 1, y: 0 }
	};

	return (
		<motion.div
			variants={variants}
			animate="visible"
			initial="hidden"
			transition={{
				y: { delay: delay ?? 0.3, ease: [0.165, 0.84, 0.44, 1], duration: 0.28 },
				opacity: { delay: delay ?? 0.3, ease: [0.165, 0.84, 0.44, 1], duration: 0.38 }
			}}>
			{children}
		</motion.div>
	);
}
