import { useMutation } from '@apollo/client';
import { useCallback, useState } from 'react';
import { LoginResponse } from '../auth/useLogin';
import { SAVE_QR_CODE } from '../graphql/mutations';
import { useInstall } from '../stores/InstallStore';
import { isNil } from 'lodash';
import { NewLocation, NewPremise } from '../models/context/Install';

export function useSaveQrCode() {
	const [saveNewQrCode] = useMutation(SAVE_QR_CODE);
	const { state } = useInstall();

	const [responseCode, setResponseCode] = useState<number>(0);

	const saveQrCode = useCallback(
		async (userData: LoginResponse | null) => {
			if (!window.localStorage.getItem('identifier')) {
				setResponseCode(500);
				console.error('The save operation could not be completed. Qr-code identifier not known.');
				return;
			}
			if (isNil(userData)) {
				setResponseCode(500);
				console.error('The save operation could not be completed. User information is not available.');
				return;
			}

			const { errors } = await saveNewQrCode({
				variables: {
					request: {
						identifier: window.localStorage.getItem('identifier'),
						premise: buildPremise(state.newPremise),
						location: buildLocation(state.newLocation),
						premiseId: state.premise?.id ?? null,
						locationId: state.location?.id ?? null,
						userId: userData.userId
					}
				}
			});
			if (!errors) {
				setResponseCode(200);
				window.localStorage.removeItem('identifier');
			} else {
				setResponseCode(500);
				console.error('Failed to save QRCode', errors);
			}
		},
		[saveNewQrCode, state.location, state.newLocation, state.newPremise, state.premise]
	);

	return { saveQrCode, responseCode };
}

function buildPremise(newPremise?: NewPremise) {
	if (!newPremise) return null;
	const { namePrefix, nameSuffix, type, floor } = newPremise;
	const name = [namePrefix, nameSuffix].filter(Boolean).join(' / ');

	return { name, buildingTypeId: type.id, floor };
}

function buildLocation(newLocation?: NewLocation) {
	if (!newLocation) return null;
	return { name: newLocation.name, buildingTypeIds: newLocation.types.map(type => type.id) };
}
