import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ActivePalette } from '../../../models/context/Session';
import { FlexRow, QuarterRow } from '../../../styles/styled-components/rows';
import { MobileBlockLabel, MobileMeasurementValue } from '../../../styles/styled-components/text';
import { backgroundConfig } from '../../../utils/helperFunctions';
import { ScoreConfig } from './ScoreTile';

type MobileFeedbackScoreProps = {
	activePalette: ActivePalette;
	config: ScoreConfig;
	data: number;
};

export function MobileFeedbackScore({ activePalette, config, data }: MobileFeedbackScoreProps) {
	const { t, unsafeTranslation } = useTypedTranslation();

	return (
		<QuarterRow mobile palette={activePalette} background={backgroundConfig(config.color)}>
			<FlexRow style={{ justifyContent: 'center', alignItems: 'center', marginBottom: '0.5rem', height: '80%' }}>
				<MobileMeasurementValue
					type="user-satisfaction"
					isDesktop={false}
					style={{ fontSize: '2.8rem', textAlign: 'center' }}
					palette={activePalette}>
					{data + ' ' + t('user-satisfaction.no-data-info')}
				</MobileMeasurementValue>
			</FlexRow>
			<MobileBlockLabel palette={activePalette} style={{ height: '20%' }}>
				{unsafeTranslation(config.text)}
			</MobileBlockLabel>
		</QuarterRow>
	);
}
