import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ActivePalette } from '../../../models/context/Session';
import { SatisfactionValueColumn } from '../../../styles/styled-components/columns';
import { ColumnContainer } from '../../../styles/styled-components/containers';
import { MobileIcon } from '../../../styles/styled-components/icons';
import { Heading3 } from '../../../styles/styled-components/text';
import { iconChooser } from '../question/iconChooser';

type SatisfactionScoreProps = {
	activePalette: ActivePalette;
	data: number;
};

export function SatisfactionScore({ activePalette, data }: SatisfactionScoreProps) {
	const { unsafeTranslation } = useTypedTranslation();

	return (
		<SatisfactionValueColumn>
			<ColumnContainer>
				<MobileIcon height="5rem" src={iconChooser(activePalette, data)} palette={activePalette} />
				<Heading3 palette={activePalette}>{unsafeTranslation(`user-satisfaction.mostAnsweredValue.${data}`)}</Heading3>
			</ColumnContainer>
		</SatisfactionValueColumn>
	);
}
