import styled from 'styled-components';
import { DetailsColumnProps, DimensionProps, QuarterColumnProps } from '../interfaces';
import { flexColumn } from './containers';

/* <--- COLUMN COMPONENTS */
export const QuarterColumn = styled('div')<QuarterColumnProps>`
	${flexColumn}
	width: 33%;
	height: 100%;
	justify-content: ${props => (props.centered ? 'center' : '')};
	align-items: ${props => (props.centered ? 'center' : '')};
`;

export const DetailsColumn = styled('div')<DetailsColumnProps>`
	${flexColumn}
	text-transform: uppercase;
	width: 13rem;
	font-size: 2rem;
	font-weight: ${props => (props.active ? 700 : 400)};
	color: ${props => (props.active ? '#A0F9D9' : '#dffdf2')};
	@media (max-width: 768px) {
		font-size: 1.6rem;
		width: 8.5rem;
	}
	@media (max-width: 550px) {
		font-size: 1.2rem;
		width: 6.5rem;
	}
`;

export const CenteredColumn = styled('div')<DimensionProps>`
	${flexColumn}
	align-items: center;
	justify-content: center;
	width: ${props => (props.fullWidth ? '100%' : 'auto')};
`;

export const SatisfactionValueColumn = styled('div')`
	${flexColumn}
	justify-content: center;
	margin-top: 2rem;
`;

export const QuestionColumn = styled('div')`
	${flexColumn}
	gap: 1rem;
	align-items: center;
`;

export const HeaderColumn = styled('div')`
	${flexColumn}
	align-items: flex-end;
	width: 80%;
`;

/* COLUMN COMPONENTS ----> */
