import { useState } from 'react';
import PinInput from 'react-pin-input';

type PinInputFieldProps = {
	onComplete: (pinCode: string) => void;
};

export function PinInputField({ onComplete }: PinInputFieldProps) {
	const [pinCode, setPinCode] = useState<string[]>([]);
	return (
		<PinInput
			focus={true}
			length={4}
			initialValue=""
			onChange={(value, index) => {
				const pin = pinCode;
				setPinCode(pin.splice(index, 1, value));
			}}
			type="numeric"
			inputMode="number"
			style={{ padding: '10px', alignContent: 'center' }}
			inputStyle={{ borderColor: '#06986a', fontFamily: 'dotsfont' }}
			inputFocusStyle={{ borderColor: '#3cb156' }}
			onComplete={() => {
				onComplete(pinCode[0]);
			}}
			autoSelect={true}
			regexCriteria={/^[ 0-9 ]*$/}
		/>
	);
}
