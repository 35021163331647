import { useState } from 'react';
import { PinCodeBrand, PinCodeModal } from '../../components/pincode/PinCodeModal';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import granlundLogo from '../../images/granlund-logo.svg';
import { useSession } from '../../stores/SessionStore';
import { ServiceRequestButton } from '../../styles/styled-components/buttons';
import { CenteredColumn } from '../../styles/styled-components/columns';
import { GranlundContainer } from '../../styles/styled-components/containers';
import { Paragraph } from '../../styles/styled-components/text';

type ServiceRequestProps = {
	serviceId: string;
	config: string;
	checkPinCode: boolean;
};

export function GranlundManagerServiceRequestView({ serviceId, config, checkPinCode }: ServiceRequestProps) {
	const { state } = useSession();
	const { t } = useTypedTranslation();
	const [doPinChallenge, setDoPinChallenge] = useState<boolean>(false);

	const onPinCodeGiven = (pinCodeOk: boolean) => {
		setDoPinChallenge(false);
		if (pinCodeOk) {
			navigateToExternalDocument();
		}
	};

	const tryOpenServiceRequest = () => {
		if (checkPinCode) {
			setDoPinChallenge(true);
		} else {
			navigateToExternalDocument();
		}
	};

	const navigateToExternalDocument = () => {
		// Safari/iOS hack-workaround, using window.open does not work.
		const link = document.getElementById('sr-link');
		if (link) {
			link.click();
		}
	};

	const brand: PinCodeBrand = {
		text: t('granlund-manager.pin-code'),
		logo: granlundLogo,
		altText: 'Granlund logo'
	};

	return (
		<GranlundContainer>
			<CenteredColumn style={{ maxWidth: 700 }} fullWidth>
				<img src={granlundLogo} style={{ height: 100, width: 100 }} alt="Granlund Logo" />
				<Paragraph palette={state.activePalette} style={{ textAlign: 'center' }}>
					{t('granlund-manager.service-request')}
				</Paragraph>
				{/* modalOpen control re/unmounts the modal, this is needed to reset the contained 'tries' count. */}
				{checkPinCode && doPinChallenge && (
					<PinCodeModal brand={brand} serviceId={serviceId} onPinCodeGiven={onPinCodeGiven} />
				)}
				<div style={{ height: 100, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
					<ServiceRequestButton
						palette={state.activePalette}
						fullWidth={!state.isDesktop}
						onClick={tryOpenServiceRequest}>
						{t('granlund-manager.create-btn')}
					</ServiceRequestButton>
					{config && (
						<a id="sr-link" target="_blank" rel="noreferrer" href={config} style={{ visibility: 'hidden' }}>
							{t('granlund-manager.create-btn')}
						</a>
					)}
				</div>
			</CenteredColumn>
		</GranlundContainer>
	);
}
