import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GET_MEASUREMENTS } from '../graphql/queries';
import { MeasurementsQueryResponse } from '../models/queries/QueryResponses';
import { setMeasurements, useSession } from '../stores/SessionStore';

export function useGetMeasurement(premiseId: string | undefined) {
	const { dispatch } = useSession();
	const [getMeasurements] = useLazyQuery<MeasurementsQueryResponse>(GET_MEASUREMENTS);

	useEffect(() => {
		async function fetchMeasurement() {
			const { data } = await getMeasurements({ variables: { premiseId: premiseId } });
			if (data) {
				dispatch(setMeasurements(data.measurements));
			}
		}
		fetchMeasurement();
	}, [dispatch, getMeasurements, premiseId]);
}
