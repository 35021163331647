import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { PremiseParamsResponse } from '../models/queries/QueryResponses';
import { GET_PREMISE_PARAMS } from '../graphql/queries';

export function useGetPremiseTypes() {
	const [getPremiseParams, { data: premise_params }] = useLazyQuery<PremiseParamsResponse>(GET_PREMISE_PARAMS);

	useEffect(() => {
		getPremiseParams();
	}, [getPremiseParams]);

	return { premiseTypes: premise_params?.buildingTypeParametersForPremise.values };
}
