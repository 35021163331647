import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ActivePalette } from '../../../models/context/Session';
import { QuestionPercentage } from '../../../models/queries/QueryResponses';
import { freesiColors, solColors } from '../../../styles/globalStyles';
import { CustomizedLabel, renderFeelisYLabel } from '../../../utils/helperFunctions';

type FeelisBarChartProps = {
	dataType: string;
	questionInfo: QuestionPercentage;
	activePalette: ActivePalette;
};

export function FeelisBarChart({ questionInfo, activePalette, dataType }: FeelisBarChartProps) {
	const { unsafeTranslation } = useTypedTranslation();

	if (!questionInfo.percentages) return null;
	const formatter = (value: number) => unsafeTranslation(`user-satisfaction.mostAnsweredValue-${dataType}.${value}`);

	return (
		<ResponsiveContainer width="100%" minHeight={'15rem'}>
			<BarChart data={questionInfo.percentages} margin={{ right: 15, left: -20 }}>
				<CartesianGrid stroke={activePalette === 'SOL' ? '#64575a' : '#bfc2d5'} vertical={false} />
				<XAxis
					dataKey="value"
					padding={{ left: -5, right: -5 }}
					style={{ fontSize: 10, fill: activePalette === 'SOL' ? '#262122' : '#00195F' }}
					tickFormatter={formatter}
					tickLine={false}
					axisLine={false}
				/>
				<YAxis domain={[0, 100]} tick={renderFeelisYLabel} tickLine={false} axisLine={false} />
				<Bar
					isAnimationActive={false}
					dataKey="percentage"
					fill={activePalette === 'SOL' ? '#E4002B' : '#00195A'}
					radius={[5, 5, 0, 0]}
					barSize={30}
					label={CustomizedLabel(questionInfo.percentages, activePalette)}>
					{questionInfo.percentages.map((entry, index) => {
						return (
							<Cell key={`cell-${index}`} fill={activePalette === 'SOL' ? solColors.solRed : freesiColors.iisyBlue} />
						);
					})}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}
