import styled from 'styled-components';
import { IconProps, PaletteProps, QuestionIconProps } from '../interfaces';

export const MobileIcon = styled('img')<IconProps>`
	height: ${props => props.height};
	filter: ${props =>
		props.palette === 'SOL' && !props.dashboard
			? 'invert(30%) sepia(75%) saturate(7051%) hue-rotate(342deg) brightness(100%) contrast(200%)'
			: props.palette === 'SOL' && props.dashboard
			? 'invert(30%) sepia(75%) saturate(7051%) hue-rotate(342deg) brightness(100%) contrast(200%)'
			: 'initial'};
`;

export const tempIconStyle = {
	height: '10em',
	alignSelf: 'flex-start',
	margin: '0 0 2rem 5rem'
};

export const DefaultIcon = styled('img')`
	height: 12em;
	@media (min-width: 2560px) {
		height: 14em;
	}
	@media (max-width: 1920px) {
		height: 9em;
	}
	@media (max-width: 1500px) {
		height: 8em;
	}
	@media (max-height: 1024px) and (max-width: 1500px) {
		height: 7em;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		height: 5.5em;
	}
`;

export const ColumnIcon = styled('img')<PaletteProps>`
	height: 11em;
	filter: ${props =>
		props.palette === 'SOL'
			? 'invert(12%) sepia(91%) saturate(6356%) hue-rotate(343deg) brightness(86%) contrast(109%)'
			: 'initial'};
	@media (max-height: 1400px) and (max-width: 1500px) {
		height: 7em;
	}
	@media (max-height: 800px) {
		height: 9em;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		height: 5em;
	}
`;

export const QuestionIcon = styled('img')<QuestionIconProps>`
	height: ${props => (props.isTempIcon ? '6.5rem' : '5.5rem')};
	width: ${props => (props.isTempIcon ? '6.5rem' : '5.5rem')};
	filter: ${props =>
		props.palette === 'SOL'
			? 'invert(0%) sepia(5%) saturate(1163%) hue-rotate(176deg) brightness(54%) contrast(89%)'
			: 'initial'};
`;

export const SummaryIcon = styled('div')`
	display: flex;
	justify-content: flex-end;
	width: 40px;
	color: #050c42;
`;
