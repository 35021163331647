import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { GET_QR } from '../graphql/queries';
import { GetQrCodeResponse } from '../models/queries/QueryResponses';
import { setCode, setLocation, setPremise, useSession } from '../stores/SessionStore';
import { useTypedTranslation } from './useTypedTranslation';

export function useGetQrCode(identifier: string | undefined, codeExists: boolean | undefined) {
	const { dispatch } = useSession();
	const { push } = useHistory();
	const { i18n } = useTypedTranslation();
	const [getQrCode] = useLazyQuery<GetQrCodeResponse>(GET_QR);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getQrCodeImpl() {
			setLoading(true);
			const { data, error } = await getQrCode({ variables: { identifier: identifier } });
			if (error) {
				console.error('Could not find the qr code: ' + error?.message);
			}
			if (data) {
				dispatch(
					setCode({
						id: data.qrCode.id,
						identifier: data.qrCode.identifier,
						premiseId: data.qrCode.premiseId,
						locationId: data.qrCode.locationId,
						questionnaireId: data.qrCode.questionnaireId
					})
				);
				dispatch(setLocation(data.qrCode.locationId));
				dispatch(setPremise(data.qrCode.premiseId));
				i18n.changeLanguage(data.qrCode.locationLanguage);
			}
			setLoading(false);
		}
		if (codeExists !== undefined) {
			if (!codeExists) {
				push('/install');
			} else {
				getQrCodeImpl();
			}
		}
	}, [dispatch, identifier, codeExists, getQrCode, i18n, push]);

	return { loading };
}
