import { useMotionValue } from 'framer-motion';
import { EndContainer } from '../../styles/styled-components/containers';
import { FailureContainer } from './FailureContainer';
import { SuccessContainer } from './SuccessContainer';

type InstallFinalViewProps = {
	responseCode: number;
};

export function InstallFinalView({ responseCode }: InstallFinalViewProps) {
	const progress = useMotionValue(90);

	return (
		<EndContainer>
			{responseCode === 200 && <SuccessContainer progress={progress} />}
			{responseCode !== 200 && <FailureContainer progress={progress} />}
		</EndContainer>
	);
}
