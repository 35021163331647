import { useEffect, useState } from 'react';
import { LoginResponse } from '../auth/useLogin';

export function useCheckValidQrCode(userData: LoginResponse | null, isAuthenticated: boolean) {
	const [inValidQrCodeIdentifier, setInValidQrCodeIdentifier] = useState<boolean>(false);

	useEffect(() => {
		if (isAuthenticated && userData && userData.hasPermission) {
			const insecureUserInputIdentifier = window.localStorage.getItem('identifier');
			if (insecureUserInputIdentifier !== null && insecureUserInputIdentifier !== undefined) {
				let whitelist: RegExp = /^[a-z-0-9_A-Z]{10}$/; // new RegExp('^[a-z-0-9_A-Z]{10}$');
				if (!whitelist.test(insecureUserInputIdentifier)) {
					setInValidQrCodeIdentifier(true);
				}
			}
		}
	}, [userData, isAuthenticated, inValidQrCodeIdentifier]);

	return { inValidQrCodeIdentifier };
}
