import { ScoreTile } from '../../components/tile/score/ScoreTile';
import { ScoreType } from '../../models/Feelis';
import { ActivePalette, LocationType } from '../../models/context/Session';
import { InfoResponse } from '../../models/queries/QueryResponses';
import { InfoMeasurementContainer } from '../../styles/styled-components/containers';
import { hasValue } from '../../utils/helperFunctions';

type InfoMeasurementColumnProps = {
	isDesktop: boolean;
	activePalette: ActivePalette;
	data: InfoResponse;
	locationType: LocationType;
};

export function InfoMeasurementColumn({ isDesktop, data, activePalette, locationType }: InfoMeasurementColumnProps) {
	const { tvocScore, particleScore, feelisScore, co2Score, lastNegFeedback } = data.infoTV;

	return (
		<InfoMeasurementContainer fullWidth={!isDesktop} palette={activePalette}>
			{hasValue(co2Score) && <ScoreTile type={ScoreType.VENTILATION} data={co2Score} />}
			{hasValue(feelisScore) && !lastNegFeedback && <ScoreTile type={ScoreType.SATISFACTION} data={feelisScore} />}
			{hasValue(lastNegFeedback) && (
				<ScoreTile type={ScoreType.SATISFACTION_NO_FEEDBACK} data={lastNegFeedback} lastNegFeedback={lastNegFeedback} />
			)}
			{locationType === LocationType.RESET && (
				<>
					{hasValue(particleScore) && <ScoreTile type={ScoreType.PARTICULATE} data={particleScore} />}
					{hasValue(tvocScore) && <ScoreTile type={ScoreType.TVOC} data={tvocScore} />}
				</>
			)}
		</InfoMeasurementContainer>
	);
}
