import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_EXTERNAL_SERVICES } from '../graphql/queries';
import { ExternalServicesQueryResponse } from '../models/queries/QueryResponses';
import { setExternalServices, useSession } from '../stores/SessionStore';
import { isEmpty } from 'lodash';

export function useExternalService(locationId: string, premiseId: string | undefined) {
	const { dispatch } = useSession();
	const [getExternalServices] = useLazyQuery<ExternalServicesQueryResponse>(GET_EXTERNAL_SERVICES);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getExternalServicesImpl() {
			setLoading(true);
			const { data, error } = await getExternalServices({
				variables: { premiseId: premiseId, locationId: locationId }
			});
			if (error) console.error(error?.message);

			if (data) {
				dispatch(setExternalServices(data.externalServices));
			}
			setLoading(false);
		}
		if (!isEmpty(locationId) && premiseId) getExternalServicesImpl();
	}, [locationId, premiseId, dispatch, getExternalServices]);

	return { loading };
}
