import { defaultLocale, SupportedLocale, supportedLocales } from '../i18n/getLocaleStrings';
import { UnsafeTranslateFn, useTypedTranslation } from '../hooks/useTypedTranslation';
import { LocalizedString } from '../models/LocalizedString';
import { TranslationKeys } from '../locales/TranslationKeys';

export function getFallbackLanguage(language: SupportedLocale, content?: Partial<LocalizedString> | null) {
	if (!content) return defaultLocale;
	return !content[language] ? defaultLocale : language;
}

export function localeMap<T>(
	valueGetter: (locale: SupportedLocale) => T,
	locales: SupportedLocale[] = supportedLocales
) {
	return locales.reduce((acc, loc) => {
		acc[loc] = valueGetter(loc);
		return acc;
	}, {} as Record<SupportedLocale, T>);
}

export function getLanguageOptions(t: UnsafeTranslateFn, withoutDefault = false, locales = supportedLocales) {
	return locales
		.filter(l => !withoutDefault || l !== defaultLocale)
		.map(l => ({
			value: l,
			label: t(`languages.name.${l}`)
		}));
}

export function useTypePhrase(type: TranslationKeys, phrase: TranslationKeys) {
	const { t } = useTypedTranslation();

	return (params: Record<string, any> = {}) =>
		t(phrase, {
			...params,
			type: t(type)
		});
}

export function usePhrase() {
	const { t } = useTypedTranslation();

	return (phrase: TranslationKeys, type: TranslationKeys, params: Record<string, any> = {}) =>
		t(phrase, {
			...params,
			type: t(type)
		});
}

export function useLocaleNameGetter() {
	const { unsafeTranslation } = useTypedTranslation();
	return (locale?: string | null) => {
		if (!locale) return '';
		const trimmedLocale = locale.toLowerCase().split('/')[0].trim();
		if (!supportedLocales.some(l => l === trimmedLocale)) return trimmedLocale;
		return unsafeTranslation(`languages.name.${locale}`);
	};
}

export function useLocaleOptions() {
	const nameGetter = useLocaleNameGetter();
	return supportedLocales.map(l => {
		return { label: nameGetter(l), value: l.toUpperCase() }; // locale enum in BE is uppercase e.g: FI
	});
}

export function useLocaleName(locale?: string | null) {
	return useLocaleNameGetter()(locale);
}
