import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { CHECK_CODE, FETCH_LID } from '../graphql/queries';
import { setCodeExists, setLocation, useSession } from '../stores/SessionStore';

export function useCheckQrCode(identifier: string | undefined, isBuildingQr: boolean) {
	const { dispatch } = useSession();
	const [checkCode] = useLazyQuery<{ checkCode: boolean }>(CHECK_CODE);
	const [fetchLocationId] = useLazyQuery<{ qrCode: { locationId: string } }>(FETCH_LID);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function checkQrCode() {
			if (!identifier) return;
			setLoading(true);
			const { data, error } = await checkCode({ variables: { identifier: identifier } });
			if (error) {
				console.error(`Could not confirm the existence of the qr code: ${identifier}`);
				console.error(error.message);
			}
			if (data) {
				dispatch(setCodeExists(data.checkCode));
				// This is only needed for old bid-code location QR-codes and their redirecting
				if (isBuildingQr) {
					const { data: location_data } = await fetchLocationId({ variables: { identifier: identifier } });
					if (location_data) {
						dispatch(setLocation(location_data.qrCode.locationId));
					}
				}
			}
			setLoading(false);
		}
		checkQrCode();
	}, [checkCode, dispatch, fetchLocationId, identifier, isBuildingQr]);

	return { loading };
}
