import { LocalizedString } from './LocalizedString';

export enum ScoreType {
	VENTILATION,
	TVOC,
	PARTICULATE,
	SATISFACTION,
	SATISFACTION_NO_FEEDBACK
}

export enum RowSize {
	HALF,
	QUARTER,
	MOBILE
}

export interface GraphData {
	time: number;
	value: number;
}

export type ActivePage = {
	id: number;
	title: string;
	question: string;
	new: string;
	formTitle?: string;
	namePrefix?: string;
	nameSuffix?: string;
};

export interface CreationParams {
	identifier: string;
	locationId?: string;
	premiseId?: string;
	location?: {
		name: string;
		types: Array<string>;
	};
	premise?: {
		name: string;
		type: string;
		floor: string;
	};
}

export interface IdName {
	id: string;
	name: string;
}

export enum ParamType {
	PREMISE,
	LOCATION
}

export interface SelectedOption {
	id: string;
	name: string;
	buildingTypeId?: string;
	buildingTypeIds?: string[];
	floor?: string;
}

export interface QuestionData {
	id: string;
	title: LocalizedString;
	options: Array<{
		label: LocalizedString;
		value: number;
	}>;
}

export interface Answer {
	id: number | string;
	value: number | string;
}
