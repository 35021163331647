import { MotionValue, motion, useTransform } from 'framer-motion';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { EndTextContainer } from '../../styles/styled-components/containers';
import { EndText } from '../../styles/styled-components/text';
import { AnimatedContainer } from '../../views/install/AnimatedContainer';
import { CircularProgress } from './CircularProgress';

type FailureContainerProps = {
	progress: MotionValue<number>;
};
export function FailureContainer({ progress }: FailureContainerProps) {
	const { t } = useTypedTranslation();
	const circleColorFailure = useTransform(progress, [0, 95, 100], ['#FFCC66', '#FFCC66', '#FF5656']);
	const motionPath = 'M 10,10 l 90,90 M 100,10 l -90,90';

	return (
		<EndTextContainer>
			<motion.div initial={{ x: 0 }} animate={{ x: 100 }} style={{ x: progress }} transition={{ duration: 1 }} />
			<CircularProgress
				progress={progress}
				circleColor={circleColorFailure}
				strokeColor="#FF5656"
				motionPath={motionPath}
			/>
			<AnimatedContainer delay={1}>
				<EndText>{t('end.failure')}</EndText>
			</AnimatedContainer>
		</EndTextContainer>
	);
}
