import { Autocomplete, TextField } from '@mui/material';
import { uniqBy } from 'lodash';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { ActivePage, IdName, SelectedOption } from '../../models/Feelis';
import { SelectionContainer, autoCompleteStyle } from '../../styles/styled-components/containers';
import { SelectionTitle } from '../../styles/styled-components/text';
import { AnimatedContainer } from '../../views/install/AnimatedContainer';

interface Props {
	data: IdName[];
	page: ActivePage;
	selection: SelectedOption | null;
	handleSelect: (event: object, value: any, reason: any) => void;
}

export function Selection({ data, page, selection, handleSelect }: Props) {
	const { t, unsafeTranslation } = useTypedTranslation();

	return (
		<AnimatedContainer>
			<SelectionContainer>
				<SelectionTitle>{unsafeTranslation(page.title)}</SelectionTitle>
				{data && (
					<Autocomplete
						value={selection}
						options={uniqBy(data, 'id')}
						sx={autoCompleteStyle}
						onChange={handleSelect}
						getOptionLabel={option => option.name as string}
						renderOption={(props, option: IdName) => (
							<li {...props} key={option.id}>
								{option.name}
							</li>
						)}
						isOptionEqualToValue={(option, value) => option.id === value.id}
						renderInput={params => <TextField {...params} placeholder={t('select')} />}
					/>
				)}
				{!selection && <span style={{ fontSize: '1.8rem' }}>{unsafeTranslation(page.question)}</span>}
			</SelectionContainer>
		</AnimatedContainer>
	);
}
