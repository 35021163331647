import { GraphTile } from '../../components/tile/graph/GraphTile';
import { useSession } from '../../stores/SessionStore';
import { MeasurementsViewContainer } from '../../styles/styled-components/containers';

export function MeasurementsView() {
	const { state } = useSession();

	return (
		<>
			{state.measurements && state.questionnaire && (
				<MeasurementsViewContainer mobile={!state.isDesktop}>
					<GraphTile
						question={state.questionnaire.questions.find(q => q.id === state.questionnaire?.tempQuestionId)}
						data={state.measurements.temperature.data}
						questionInfo={state.measurements.temperature.temperatureScorePercentageMonth}
					/>
					<GraphTile
						question={state.questionnaire?.questions.find(q => q.id === state.questionnaire?.co2QuestionId)}
						data={state.measurements.co2.data}
						questionInfo={state.measurements.co2.co2ScorePercentageMonth}
					/>
				</MeasurementsViewContainer>
			)}
		</>
	);
}
