import { LocalizedString } from '../LocalizedString';

export interface Parameter {
	id: string;
	name: LocalizedString;
	values: Array<ParameterValue>;
}

// @ts-ignore
export interface ParameterValue {
	id: string;
	name: LocalizedString;
}

export interface Location {
	id: string;
	name: string;
}

export interface Premise {
	id: string;
	name: string;
}

export interface PremiseInput {
	name: string;
	buildingTypeId: string;
	floor: number;
}

export interface LocationInput {
	name: string;
	buildingTypeIds: string[];
}

export interface NewLocation {
	name: string;
	types: Array<{
		id: string;
		name: LocalizedString;
	}>;
}

export interface NewPremise {
	namePrefix: string;
	nameSuffix: string;
	type: {
		id: string;
		name: LocalizedString;
	};
	floor: number;
}

export enum InstallActionTypes {
	SET_AUTH,
	SET_DISABLE,
	SET_SELECTED_LOCATION,
	SET_SELECTED_PREMISE,
	SET_SELECTED_LOCATION_ID,
	SET_NEW_LOCATION,
	SET_NEW_PREMISE,
	SET_LOCATION_PARAMS,
	SET_PREMISE_PARAMS
}

export interface SetAuthenticated {
	type: InstallActionTypes.SET_AUTH;
	authenticated: boolean;
}

export interface SetDisableState {
	type: InstallActionTypes.SET_DISABLE;
	disableState: boolean;
}

export interface SetSelectedLocation {
	type: InstallActionTypes.SET_SELECTED_LOCATION;
	location: Location | undefined;
}

export interface SetSelectedPremise {
	type: InstallActionTypes.SET_SELECTED_PREMISE;
	premise: Premise | undefined;
}

export interface SetSelectedLocationId {
	type: InstallActionTypes.SET_SELECTED_LOCATION_ID;
	selectedLocationId: string | undefined;
}

export interface SetNewPremise {
	type: InstallActionTypes.SET_NEW_PREMISE;
	newPremise: NewPremise | undefined;
}

export interface SetNewLocation {
	type: InstallActionTypes.SET_NEW_LOCATION;
	newLocation: NewLocation | undefined;
}

export interface SetLocationParams {
	type: InstallActionTypes.SET_LOCATION_PARAMS;
	locationParams: Parameter;
}

export interface SetPremiseParams {
	type: InstallActionTypes.SET_PREMISE_PARAMS;
	premiseParams: Parameter;
}

export type InstallActions =
	| SetDisableState
	| SetAuthenticated
	| SetSelectedLocation
	| SetSelectedPremise
	| SetSelectedLocationId
	| SetNewPremise
	| SetNewLocation
	| SetLocationParams
	| SetPremiseParams;

export interface InstallContextState {
	authenticated: boolean;
	disableState: boolean;
	location: Location | undefined;
	premise: Premise | undefined;
	newLocation: NewLocation | undefined;
	newPremise: NewPremise | undefined;
}

export interface IInstallContext {
	state: InstallContextState;
	dispatch: React.Dispatch<InstallActions>;
}
