import { SupportedLocale } from '../models/LocalizedString';
import { QrCode, TempQrCode } from '../models/queries/QueryResponses';
import { AnalyticsEvent, AnalyticsEventType } from './AnalyticsEvent';
import { AnalyticsService } from './AnalyticsService';
import { DummyAnalyticsService } from './DummyAnalyticsService';
import { GoogleAnalyticsService } from './GoogleAnalyticsService';

export class AnalyticsFacade {
	private readonly qrCode?: QrCode | TempQrCode;
	private readonly locale?: SupportedLocale;
	private readonly questionsAnswered?: string[];
	private readonly analyticsService: AnalyticsService;

	constructor({
		qrCode,
		locale,
		questionsAnswered
	}: {
		qrCode?: QrCode | TempQrCode;
		locale?: SupportedLocale;
		questionsAnswered?: string[];
	}) {
		this.analyticsService = createAnalyticsService();
		this.qrCode = qrCode;
		this.locale = locale;
		this.questionsAnswered = questionsAnswered;
	}

	trackEvent(type: AnalyticsEventType, data?: any) {
		const event: AnalyticsEvent = {
			type,
			data,
			qrCode: this.qrCode?.identifier,
			locale: this.locale,
			questionsAnswered: this.questionsAnswered
		};
		this.analyticsService.trackEvent(event);
	}

	trackPageChange(pathName: string) {
		this.trackEvent('page_change', pathName);
	}

	trackTabChange(newTab: string) {
		this.trackEvent('tab_change', newTab);
	}
}

function createAnalyticsService(): AnalyticsService {
	try {
		if (process.env.REACT_APP_GA_TRACKING_ID) return new GoogleAnalyticsService();
	} catch (e) {}
	console.warn('no analytics implementation available, defaulting to dummy');
	return new DummyAnalyticsService();
}
