import { getI18n, useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { defaultLocale, SupportedLocale, supportedLocales } from '../i18n/getLocaleStrings';
import { isNil } from '../utils/typeGuards';
import { TranslationKeys } from '../locales/TranslationKeys';

export function useTypedTranslation() {
	const { t, i18n, ...others } = useTranslation();
	return {
		t: useCallback((key: TranslationKeys, variables?: Record<string, any>) => t(key, prepareVariables(variables)), [t]),
		unsafeTranslation: t as (key: string) => string,
		i18n,
		language: i18n.language as SupportedLocale,
		...others
	};
}

/**
 * hack to turn variable to variable, variable__upper etc
 * @param variables
 */
function prepareVariables(variables: Record<string, any> = {}) {
	return Object.fromEntries(Object.entries(variables).flatMap(([k, v]) => Object.entries(prepareVariable(k, v))));
}

function prepareVariable(k: string, v: any): Record<string, any> {
	if (typeof v !== 'string') return { [k]: v };
	const ret: Record<string, string> = {};
	ret[k] = v;
	ret[k + '__upper'] = v.toUpperCase();
	ret[k + '__lower'] = v.toLowerCase();
	ret[k + '__capitalized'] = v[0].toUpperCase() + v.slice(0);

	return ret;
}

export type TranslateFn = ReturnType<typeof useTypedTranslation>['t'];
export type UnsafeTranslateFn = ReturnType<typeof useTypedTranslation>['unsafeTranslation'];

export function typedTranslate(key: TranslationKeys) {
	const i18n = getI18n();
	if (!i18n) {
		return key;
	}
	return i18n.t(key);
}

export function getLanguage() {
	return getI18n().language as SupportedLocale;
}

export function getSupportedLocaleOrDefault(locale?: String) {
	const language = locale?.toLowerCase() as SupportedLocale;
	const localeKey = !isNil(language) && supportedLocales.includes(language) ? language : defaultLocale;
	return localeKey;
}
