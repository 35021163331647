import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { useInstall } from '../../stores/InstallStore';
import { CancelButton, ToolbarButton } from '../../styles/styled-components/buttons';
import { ToolbarContainer } from '../../styles/styled-components/containers';

interface Props {
	activePage: number;
	creatingPremise: boolean;
	creatingLocation: boolean;
	handleSave: () => void;
	handleCancel: () => void;
	nextPage: () => void;
	previousPage: () => void;
}

export function Toolbar({
	activePage,
	creatingPremise,
	creatingLocation,
	handleCancel,
	handleSave,
	nextPage,
	previousPage
}: Props) {
	const { t } = useTypedTranslation();
	const { state } = useInstall();

	// TODO: this is refactored and mimic of the old logic => find a better solution
	const handleAsBack = (activePage === 2 && !creatingPremise) || activePage === 3;
	const handleAsCancel = (activePage === 1 && creatingLocation) || (activePage === 2 && creatingPremise);
	const LAST_PAGE = 3;

	return (
		<ToolbarContainer>
			{handleAsBack && (
				<CancelButton color="primary" variant="outlined" onClick={previousPage}>
					{t('navigation.back')}
				</CancelButton>
			)}
			{handleAsCancel && (
				<CancelButton color="primary" variant="outlined" onClick={handleCancel}>
					{t('navigation.back')}
				</CancelButton>
			)}
			{activePage === LAST_PAGE && (
				<ToolbarButton color="primary" variant="outlined" onClick={handleSave} disabled={state.disableState}>
					{t('navigation.save')}
				</ToolbarButton>
			)}
			{activePage !== LAST_PAGE && (
				<ToolbarButton color="primary" variant="outlined" onClick={nextPage} disabled={state.disableState}>
					{t('navigation.next')}
				</ToolbarButton>
			)}
		</ToolbarContainer>
	);
}
