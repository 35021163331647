import styled from 'styled-components';
import {
	bodyText__Mobile,
	ellipsisText,
	freesiColors,
	headingText__Mobile,
	secondaryText__Mobile,
	solColors,
	topicText__Mobile
} from '../globalStyles';
import { DataProps, MeasurementValueProps, MobileProps, PaletteProps } from '../interfaces';

/* <--- TEXT COMPONENTS */
export const Paragraph = styled('span')<PaletteProps>`
	${secondaryText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195A')};
`;

export const Heading2 = styled('span')<PaletteProps>`
	${topicText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195A')};
`;

export const Heading3 = styled('span')<PaletteProps>`
	${headingText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195A')};
`;

export const Heading4 = styled('span')<PaletteProps>`
	${bodyText__Mobile}
	color: ${props => (props.palette === 'SOL' ? solColors.solLightBlack : freesiColors.iisyBlue)};
	text-align: center;
`;

export const LastWeek = styled('span')<PaletteProps>`
	${bodyText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195A')};
	text-align: center;
`;

export const GraphSatisfactionTitle = styled('span')<PaletteProps>`
	${bodyText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195A')};
	text-align: center;
`;

export const LocationTitle = styled('div')<MobileProps>`
	${ellipsisText}
	${props => (props.mobile ? bodyText__Mobile : headingText__Mobile)}
	color: ${props => (props.palette === 'SOL' ? solColors.solLightBlack : freesiColors.iisyBlue)};
	text-align: end;
	width: 100%;
	padding-top: ${props => (props.mobile ? '1rem' : 'auto')};
`;

export const IndoorAirTitle = styled('div')<MobileProps>`
	${ellipsisText}
	${bodyText__Mobile}
	width: 100%;
	color: ${props => (props.palette === 'SOL' ? solColors.solLightBlack : freesiColors.iisyBlue)};
	text-align: end;
`;

export const ColumnTitle = styled('h3')<MobileProps>`
	margin: 3rem;
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195a')};
	font-size: 2.4rem;
	font-weight: 500;
	text-align: auto;
	width: auto;
	@media (max-width: 760px) {
		font-size: 2.1rem;
	}
`;

export const BigTitle = styled('h3')<PaletteProps>`
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195a')};
	font-size: 4rem;
	font-weight: 700;
	@media (max-width: 760px) {
		font-size: 3rem;
	}
	@media (max-height: 1000px) and (max-width: 1500px) {
		font-size: 3rem;
	}
`;

export const DefaultTypeTitle = styled('h3')`
	margin: 0;
	color: #00195a;
	font-size: 5.5rem;
	@media (max-width: 768px) {
		font-size: 3.2rem;
	}
	@media (min-width: 1200px) and (max-width: 1500px) {
		font-size: 4rem;
	}
	@media (min-width: 3300px) {
		font-size: 7.7rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 3.2rem;
	}
`;

export const DefaultTypeValue = styled('div')`
	color: #00195a;
	font-size: 5.5rem;
	font-weight: 700;
	@media (max-width: 768px) {
		font-size: 3.6rem;
	}
	@media (min-width: 1200px) and (max-width: 1500px) {
		font-size: 4rem;
	}
	@media (min-width: 3300px) {
		font-size: 7.7rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 3.4rem;
	}
`;

export const MeasurementTitle = styled('div')<PaletteProps>`
	text-align: center;
	font-size: 5rem;
	color: ${props => (props.palette === 'SOL' ? '#E4002B' : '#dffdf2')};

	@media (min-width: 3300px) {
		font-size: 7rem;
	}
	@media (max-width: 970px) {
		font-size: 3.6rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 3.2rem;
	}
`;

export const MeasurementValue = styled('div')<MeasurementValueProps>`
	font-size: ${props => (props.type === 'no-data' ? '4rem' : '15rem')};
	margin ${props => (props.type === 'no-data' ? '6rem' : '0rem')};
	text-align: center;
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#a0f9d9')};
	font-weight: 700;
	@media (min-width: 3300px) {
		font-size: 18rem;
	}
	@media (max-width: 970px) {
		font-size: 12.4rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 12rem;
	}
`;

export const MobileBlockLabel = styled('div')<PaletteProps>`
	font-size: 1.5rem;
	font-weight: 500;
	display: flex;
	justify-content: flex-end;
	color: ${props => (props.palette === 'SOL' ? solColors.solLightBlack : freesiColors.iisyBlue)};
`;

export const MobileBlockTitle = styled('div')<PaletteProps>`
	${topicText__Mobile}
	margin-top: 1rem;
	color: ${props => (props.palette === 'SOL' ? solColors.solLightBlack : freesiColors.iisyBlue)};
`;

export const MobileMeasurementValue = styled('div')<MeasurementValueProps>`
	font-size: 2.5rem;
	color: ${props =>
		props.palette === 'SOL'
			? solColors.solLightBlack
			: props.type === 'measurementTemp' && props.palette === 'FREESI'
			? freesiColors.iisyGreen
			: freesiColors.iisyBlue};
	font-weight: 700;
	text-align: center;
`;

export const NoUserSatisfactoryData = styled('div')`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 1.4rem;
	color: #00195a;
`;

export const TemperatureInfo = styled('div')<PaletteProps>`
	font-size: 3rem;
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#dffdf2')};
	@media (min-width: 3300px) {
		font-size: 4rem;
	}
	@media (max-width: 970px) {
		font-size: 2.2rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 3rem;
	}
`;

export const FloorsInfo = styled('div')`
	font-size: 2rem;
	color: #dffdf2;
	margin-top: 1rem;
	@media (min-width: 3300px) {
		font-size: 3rem;
	}
	@media (max-width: 970px) {
		font-size: 1.2rem;
	}
	@media (max-height: 910px) and (max-width: 1500px) {
		font-size: 2rem;
	}
`;

export const QuestionnaireTitle = styled('div')<PaletteProps>`
	${headingText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195a')};
	display: flex;
	width: 100%;
	justify-content: center;
	margin-bottom: 1rem;
`;

export const GraphTileMeasurementTitle = styled('span')<PaletteProps>`
	${headingText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195a')};
	text-align: center;
	margin-right: 4rem;
`;

export const MeasurementIconBox = styled('div')<DataProps>`
	width: 30%;
	display: flex;
	justify-content: ${props => (props.data ? 'flex-end' : 'center')};
	margin: ${props => (props.data ? 'none' : '0 auto')};
`;

export const QuestionLabel = styled('h3')<PaletteProps>`
	${bodyText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195a')};
	text-align: center;
`;

export const SummaryContentKey = styled('span')`
	font-size: 0.9rem;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	width: 30%;
`;

export const SummaryContentValue = styled('span')`
	font-size: 1.2rem;
	width: 70%;
`;

export const EndText = styled('span')`
	font-size: 1.5rem;
	display: block;
	text-align: center;
	margin-top: 1rem;
`;

export const IconLabel = styled('label')`
	text-align: center;
`;

export const QuestionOptionText = styled('span')`
	${secondaryText__Mobile}
	text-align: center;
	color: #00195a;
`;

export const FourLabel = styled('span')`
	${secondaryText__Mobile}

	&:first-of-type {
		margin-left: 2%;
		margin-right: 0;
	}

	&:last-of-type {
		margin-left: 0;
		margin-right: 2%;
	}
`;

export const DayCounterNumber = styled('span')<PaletteProps>`
	transform: translate(56%, -47%);
	position: absolute;
	top: 50%;
	right: 50%;
	color: ${props => (props.palette === 'SOL' ? '#E4002B' : ' #00195a')};
	letter-spacing: 1.5px;
	font-weight: 500;
	font-size: 3rem;
`;

export const SelectionTitle = styled('h1')`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0;
	color: #171a2b;
	font-size: 1.8rem;
	font-weight: 500;
`;

export const SummaryRowTitle = styled('span')`
	color: #050c42;
	font-size: 1.2rem;
`;

export const ErrorViewText = styled('div')`
	font-size: 1.3rem;
`;

export const SatisfactionTileText = styled('span')<PaletteProps>`
	${secondaryText__Mobile}
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#00195a')};
	text-align: center;
`;

export const PinCodeModalText = styled('p')`
	${secondaryText__Mobile}
	display: flex;
	flex-direction: column;
	justify-content: center;
	color: #00195a;
`;

export const ThankYouText = styled('div')`
	${secondaryText__Mobile}
	text-align: center;
	color: #00195a;
`;

/* TEXT COMPONENTS ----> */
