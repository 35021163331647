import { useLogin } from '../../auth/useLogin';
import { InstallFinalView } from '../../components/end/InstallFinalView';
import { LanguagePicker } from '../../components/language-picker/LanguagePicker';
import { Pages } from '../../components/pages/Pages';
import { Summary } from '../../components/summary/Summary';
import { Toolbar } from '../../components/toolbar/Toolbar';
import { useCheckValidQrCode } from '../../hooks/useCheckValidQrCode';
import { useGetLocations } from '../../hooks/useGetLocations';
import { pages, useHandlePaging } from '../../hooks/useHandlePaging';
import logo from '../../images/logo_with_bars.svg';
import { useInstall } from '../../stores/InstallStore';
import { FlexContainer } from '../../styles/styled-components/containers';
import { Logo, StyledHeader } from '../../styles/styled-components/header';
import { NoContent } from '../error/NoContent';
import { PermissionDenied } from '../error/PermissionDenied';
import { LocationPage } from './LocationPage';
import { PremisePage } from './PremisePage';

export function InstallView() {
	const { state } = useInstall();
	const { userData, keycloakAuthenticated } = useLogin();
	const { locations, locations_error } = useGetLocations(userData, state.authenticated);
	const { inValidQrCodeIdentifier } = useCheckValidQrCode(userData, state.authenticated);

	const {
		pageNumber,
		activePage,
		setLocationInCreation,
		setPremiseInCreation,
		locationInCreation,
		premiseInCreation,
		handleSave,
		handleCancel,
		nextPage,
		previousPage,
		responseCode
	} = useHandlePaging(userData);

	const invalidOrNoQrCode = inValidQrCodeIdentifier || !window.localStorage.getItem('identifier');
	if (userData && !userData.hasPermission) return <PermissionDenied />;
	if (locations_error && !locations) return <div>Data load error</div>; // TODO: add proper error handling
	if (invalidOrNoQrCode) return <NoContent inValidQrCodeIdentifier={invalidOrNoQrCode} />;

	if (!keycloakAuthenticated && !state.authenticated) return null;

	return (
		<FlexContainer>
			<StyledHeader mobile>
				<Logo mobile src={logo} />
				<LanguagePicker />
			</StyledHeader>
			{pageNumber <= pages.length && <Pages active={pageNumber} amount={3} />}
			{pageNumber === 1 && locations && (
				<LocationPage
					page={activePage}
					data={locations}
					setLocationInCreation={setLocationInCreation}
					setPremiseInCreation={setPremiseInCreation}
					locationInCreation={locationInCreation}></LocationPage>
			)}
			{pageNumber === 2 && (
				<PremisePage
					page={activePage}
					setPremiseInCreation={setPremiseInCreation}
					premiseInCreation={premiseInCreation}
					locationInCreation={locationInCreation}></PremisePage>
			)}
			{pageNumber === 3 && <Summary page={activePage} />}
			{pageNumber === 4 && <InstallFinalView responseCode={responseCode} />}
			{pageNumber <= pages.length && (
				<Toolbar
					creatingPremise={premiseInCreation}
					creatingLocation={locationInCreation}
					activePage={pageNumber}
					handleCancel={handleCancel}
					handleSave={handleSave}
					nextPage={nextPage}
					previousPage={previousPage}
				/>
			)}
		</FlexContainer>
	);
}
