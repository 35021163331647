import { useLazyQuery } from '@apollo/client';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { GET_DASHBOARD } from '../graphql/queries';
import { DashboardQueryResponse } from '../models/queries/QueryResponses';
import { setDashboard, useSession } from '../stores/SessionStore';

export function useGetDashboardData(locationId: string, premiseId: string | undefined) {
	const { dispatch } = useSession();
	const [getDashboard] = useLazyQuery<DashboardQueryResponse>(GET_DASHBOARD);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function getDashboardImpl() {
			setLoading(true);
			const { data, error } = await getDashboard({ variables: { locationId: locationId, premiseId: premiseId } });
			if (error) console.error(error?.message);
			if (data) {
				dispatch(setDashboard(data.dashboard));
				window.sessionStorage.setItem('session', uuid());
			}
			setLoading(false);
		}
		if (!isEmpty(locationId) && premiseId) getDashboardImpl();
	}, [locationId, premiseId, dispatch, getDashboard]);

	return { loading };
}
