import { Autocomplete, TextField } from '@mui/material';
import { useCreateLocation } from '../../hooks/useCreateLocation';
import { useGetLocationTypes } from '../../hooks/useGetLocationTypes';
import { usePremiseInCreation } from '../../hooks/usePremiseInCreation';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { useWithFallbackContent } from '../../hooks/useWithFallbackContent';
import { ActivePage } from '../../models/Feelis';
import { ParameterValue } from '../../models/context/Install';
import { SelectionContainer, autoCompleteStyle } from '../../styles/styled-components/containers';
import { SelectionTitle } from '../../styles/styled-components/text';
import { AnimatedContainer } from './AnimatedContainer';

type CreateLocationProps = {
	page: ActivePage;
	setPremiseInCreation: (bool: boolean) => void;
};

export function CreateLocation({ page, setPremiseInCreation }: CreateLocationProps) {
	usePremiseInCreation(setPremiseInCreation);
	const { t, unsafeTranslation } = useTypedTranslation();
	const { locationTypes } = useGetLocationTypes();
	const withFallback = useWithFallbackContent();

	const { createdLocation, setCreatedLocation } = useCreateLocation();

	function handleTypesChangeLocation(e: any, value: ParameterValue[]) {
		setCreatedLocation({ ...createdLocation, types: value });
	}

	function handleTextChange(e: React.ChangeEvent<HTMLInputElement>) {
		setCreatedLocation({
			...createdLocation,
			[e.target.name]: e.target.value
		});
	}

	return (
		<AnimatedContainer>
			<SelectionContainer>
				<SelectionTitle>{unsafeTranslation(page.new)}</SelectionTitle>
				<Autocomplete
					multiple
					options={locationTypes || []}
					getOptionLabel={option => withFallback(option.name) ?? option.id}
					onChange={handleTypesChangeLocation}
					sx={autoCompleteStyle}
					renderInput={params => <TextField {...params} label={t('location.type')} variant="standard" />}
				/>
				{page.formTitle && (
					<TextField
						name="name"
						label={unsafeTranslation(page.formTitle)}
						variant="outlined"
						type="text"
						onChange={handleTextChange}
						sx={autoCompleteStyle}
					/>
				)}
			</SelectionContainer>
		</AnimatedContainer>
	);
}
