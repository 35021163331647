import { ServiceRequestType, supportedServices } from '../../models/ServiceRequestType';
import { ExternalServices } from '../../models/queries/QueryResponses';
import { GranlundManagerServiceRequestView } from './GranlundManagerServiceRequestView';

type ServiceRequestProps = {
	services?: ExternalServices;
};

export function ServiceRequestView({ services }: ServiceRequestProps) {
	if (!services) return null;
	const service = effectiveService(services);
	if (!service) return null;

	return (
		<>
			{service.name === ServiceRequestType.GranlundManager && (
				<GranlundManagerServiceRequestView serviceId={service.id} config={service.config} checkPinCode={service.pin} />
			)}
		</>
	);
}

function effectiveService(services: ExternalServices) {
	return services.find(svc => supportedServices.includes(svc.name));
}
