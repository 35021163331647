import { useScoreConfigMapper } from '../../../hooks/useScoreConfigMapper';
import { ScoreType } from '../../../models/Feelis';
import { useSession } from '../../../stores/SessionStore';
import { DesktopFeedbackScore } from './DesktopFeedbackScore';
import { DesktopScore } from './DesktopScore';
import { MobileFeedbackScore } from './MobileFeedbackScore';
import { MobileScore } from './MobileScore';

interface ScoreProps {
	data: number;
	type: ScoreType;
	lastNegFeedback?: number | null;
	isDashboard?: boolean;
}

export interface ScoreConfig {
	icon: string | null;
	color: string;
	text: string;
}

export function ScoreTile({ data, type, isDashboard, lastNegFeedback }: ScoreProps) {
	const { state } = useSession();
	const { config } = useScoreConfigMapper(type, isDashboard, data, state.activePalette);

	if (!config) return null;
	return (
		<>
			{/* InfoTv mobile view */}
			{!state.isDesktop && !lastNegFeedback && (
				<MobileScore
					isDashboard={isDashboard}
					activePalette={state.activePalette}
					config={config}
					data={data}
					type={type}></MobileScore>
			)}
			{/* InfoTv mobile view (only feelis without answers) */}
			{!state.isDesktop && lastNegFeedback && (
				<MobileFeedbackScore
					activePalette={state.activePalette}
					config={config}
					data={lastNegFeedback}></MobileFeedbackScore>
			)}
			{/* InfoTv large screens */}
			{state.isDesktop && !lastNegFeedback && (
				<DesktopScore activePalette={state.activePalette} config={config} data={data} type={type}></DesktopScore>
			)}
			{/* InfoTv large screens (only feelis without answers) */}

			{state.isDesktop && lastNegFeedback && (
				<DesktopFeedbackScore
					activePalette={state.activePalette}
					config={config}
					data={lastNegFeedback}></DesktopFeedbackScore>
			)}
		</>
	);
}
