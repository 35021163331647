import { useState } from 'react';
import { Selection } from '../../components/selection/Selection';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { ActivePage, IdName, SelectedOption } from '../../models/Feelis';
import { setDisableState, setSelectedLocation, useInstall } from '../../stores/InstallStore';
import { ToolbarButton } from '../../styles/styled-components/buttons';
import { InstallViewContainer } from '../../styles/styled-components/containers';
import { SelectionRow } from '../../styles/styled-components/rows';
import { CreateLocation } from './CreateLocation';

type CreateLocationProps = {
	page: ActivePage;
	data: IdName[];
	setLocationInCreation: (bool: boolean) => void;
	setPremiseInCreation: (bool: boolean) => void;
	locationInCreation: boolean;
};

export function LocationPage({
	page,
	data,
	setLocationInCreation,
	setPremiseInCreation,
	locationInCreation
}: CreateLocationProps) {
	const { unsafeTranslation } = useTypedTranslation();
	const { dispatch } = useInstall();

	const [selection, setSelection] = useState<SelectedOption | null>(null);

	const handleClickNew = () => {
		setLocationInCreation(true);
		dispatch(setSelectedLocation(undefined));
	};

	const handleSelect = (event: object, value: any, reason: any) => {
		if (reason === 'selectOption') {
			dispatch(setSelectedLocation({ id: value.id, name: value.name }));
			setSelection(value);
			dispatch(setDisableState(false));
		}
		if (reason === 'clear') {
			dispatch(setSelectedLocation(undefined));
			setSelection(null);
			dispatch(setDisableState(true));
		}
	};

	return (
		<InstallViewContainer>
			{!locationInCreation && (
				<Selection data={data} page={page} selection={selection} handleSelect={handleSelect}></Selection>
			)}
			{locationInCreation && <CreateLocation page={page} setPremiseInCreation={setPremiseInCreation}></CreateLocation>}
			{!selection && !locationInCreation && (
				<SelectionRow>
					<ToolbarButton onClick={handleClickNew}>{unsafeTranslation(page.new)}</ToolbarButton>
				</SelectionRow>
			)}
		</InstallViewContainer>
	);
}
