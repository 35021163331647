import { useLazyQuery } from '@apollo/client';
import { isNil } from 'lodash';
import { useEffect } from 'react';
import { LoginResponse } from '../auth/useLogin';
import { GET_LOCATIONS } from '../graphql/queries';
import { LocationsResponse } from '../models/queries/QueryResponses';
import { trimAndSort } from '../utils/helperFunctions';

export function useGetLocations(userData: LoginResponse | null, isAuthenticated: boolean) {
	const [getData, { data: locations_data, error: locations_error }] = useLazyQuery<LocationsResponse>(GET_LOCATIONS);

	useEffect(() => {
		if (isNil(userData)) return;
		if (userData.hasPermission && isAuthenticated) getData({ variables: { userId: userData.userId } });
	}, [getData, isAuthenticated, userData]);

	return { locations: locations_data ? trimAndSort(locations_data.locations) : [], locations_error };
}
