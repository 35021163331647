import { useMemo } from 'react';
import { AnalyticsFacade } from './AnalyticsFacade';
import { useSession } from '../stores/SessionStore';
import { SupportedLocale } from '../i18n/getLocaleStrings';

export function useAnalytics() {
	const { state } = useSession();
	const qrCode = state.code;
	const locale = state.language as SupportedLocale;
	const questionsAnswered = state.questionsAnswered;
	const analytics = useMemo(
		() =>
			new AnalyticsFacade({
				qrCode,
				locale,
				questionsAnswered
			}),
		[qrCode, locale, questionsAnswered]
	);
	return analytics;
}
