import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import infoTvError from '../../images/infoTV_error.webp';
import { ErrorViewRootContainer } from '../../styles/styled-components/containers';

export function InfoTVError() {
	const { t } = useTypedTranslation();
	return (
		<ErrorViewRootContainer style={{ gap: '5%' }}>
			<img
				src={infoTvError}
				style={{
					height: '50%',
					aspectRatio: '1',
					objectFit: 'cover',
					border: '5px dashed red',
					borderRadius: '50%'
				}}
				alt="Encounter Error"
			/>
			<span style={{ fontSize: '2.2rem', fontWeight: 'bold', color: '#00195f' }}>{t('core.error')}</span>
		</ErrorViewRootContainer>
	);
}
