import { useEffect, useState } from 'react';
import { setDisableState, setNewLocation, useInstall } from '../stores/InstallStore';
import { NewLocation } from '../models/context/Install';

export function useCreateLocation() {
	const { dispatch } = useInstall();

	const [createdLocation, setCreatedLocation] = useState<NewLocation>({ name: '', types: [] });

	useEffect(() => {
		if (createdLocation && createdLocation.name.length && createdLocation.types.length > 0) {
			dispatch(setDisableState(false));
			dispatch(setNewLocation(createdLocation));
		} else {
			dispatch(setDisableState(true));
		}
	}, [createdLocation, dispatch]);

	return { createdLocation, setCreatedLocation };
}
