import axios from 'axios';
import { LoginResponse } from './useLogin';

const LOGIN_URL = process.env.REACT_APP_LOGIN_URL;

export const loginApi = {
	login: async (token: string) => {
		try {
			const { data } = await axios.get<LoginResponse>(`${LOGIN_URL}`, {
				headers: {
					Authorization: `Bearer ${token}`
				}
			});
			return data;
		} catch (error: any) {
			throw JSON.parse(JSON.stringify(error.response));
		}
	}
};
