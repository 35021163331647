import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ScoreType } from '../../../models/Feelis';
import { ActivePalette } from '../../../models/context/Session';
import { MobileIcon } from '../../../styles/styled-components/icons';
import { FlexRow, QuarterRow } from '../../../styles/styled-components/rows';
import { MobileBlockLabel, MobileMeasurementValue } from '../../../styles/styled-components/text';
import { backgroundConfig } from '../../../utils/helperFunctions';
import { scoreMapper } from '../../../views/infotv/infoHelper';
import { ScoreConfig } from './ScoreTile';

type MobileScoreProps = {
	isDashboard: boolean | undefined;
	activePalette: ActivePalette;
	config: ScoreConfig;
	data: number;
	type: ScoreType;
};

export function MobileScore({ activePalette, config, data, type, isDashboard }: MobileScoreProps) {
	const { unsafeTranslation } = useTypedTranslation();

	return (
		<QuarterRow fullWidth={isDashboard} mobile palette={activePalette} background={backgroundConfig(config.color)}>
			<FlexRow
				style={{
					alignItems: 'start',
					height: '15%'
				}}>
				{config.icon && (
					<MobileIcon height={'3.5rem'} src={config.icon} alt={`${config.text}-icon`} palette={activePalette} />
				)}
			</FlexRow>
			<FlexRow style={{ justifyContent: 'center', alignItems: 'center', marginBottom: '0.5rem', height: '70%' }}>
				<MobileMeasurementValue type="measurement" isDesktop={false} palette={activePalette}>
					{typeof data === 'number' && unsafeTranslation(scoreMapper(data, type))}
				</MobileMeasurementValue>
			</FlexRow>
			<MobileBlockLabel palette={activePalette}>{unsafeTranslation(config.text)}</MobileBlockLabel>
		</QuarterRow>
	);
}
