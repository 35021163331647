import { ActivePalette } from '../../../models/context/Session';
import smileycold from '../../../images/smiley-cold.svg';
import smileyfreezing from '../../../images/smiley-freezing.svg';
import smileyhappy from '../../../images/smiley-happy.svg';
import smileyhot from '../../../images/smiley-hot.svg';
import smileyneutral from '../../../images/smiley-neutral.svg';
import smileyperfect from '../../../images/smiley-perfect.svg';
import smileysad from '../../../images/smiley-sad.svg';
import smileyvhappy from '../../../images/smiley-vhappy.svg';
import smileyvsad from '../../../images/smiley-vsad.svg';
import smileywarm from '../../../images/smiley-warm.svg';

import happy from '../../../images/bear-happy.svg';
import neutral from '../../../images/bear-neutral.svg';
import sad from '../../../images/bear-sad.svg';
import vhappy from '../../../images/bear-vhappy.svg';
import vsad from '../../../images/bear-vsad.svg';
import cold from '../../../images/cold.svg';
import freezing from '../../../images/freezing1.svg';
import hot from '../../../images/hot1.svg';
import perfect from '../../../images/perfect.svg';
import warm from '../../../images/warm.svg';

export function iconChooser(
	palette: ActivePalette,
	optionValue: number,
	numberOfOptions?: number,
	isTempQuestion?: boolean
) {
	const iconSet = isTempQuestion ? getTempIconSet(palette) : getQuestionIconSet(palette, numberOfOptions);

	if (optionValue >= 0 && optionValue <= 4) {
		return iconSet[optionValue];
	}
	throw new Error(`Invalid icon value for user satisfaction: ${optionValue}`);
}

const getTempIconSet = (palette: ActivePalette) =>
	palette === ActivePalette.SOL
		? [smileyfreezing, smileycold, smileyperfect, smileywarm, smileyhot]
		: [freezing, cold, perfect, warm, hot];

function getQuestionIconSet(palette: ActivePalette, numberOfOptions: number | undefined) {
	if (numberOfOptions === 3) {
		// when question has 3 options, min-max icon is the same sad icon
		return palette === ActivePalette.SOL ? [smileysad, smileyneutral, smileysad] : [sad, neutral, sad];
	} else if (numberOfOptions === 4) {
		// when question has 4 options, skip the second icon
		return palette === ActivePalette.SOL
			? [smileyvsad, smileyneutral, smileyhappy, smileyvhappy]
			: [vsad, neutral, happy, vhappy];
	} else {
		return palette === ActivePalette.SOL
			? [smileyvsad, smileysad, smileyneutral, smileyhappy, smileyvhappy]
			: [vsad, sad, neutral, happy, vhappy];
	}
}
