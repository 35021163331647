import { ScoreTile } from '../../components/tile/score/ScoreTile';
import { MobileTempTile } from '../../components/tile/temperature/MobileTempTile';
import { ScoreType } from '../../models/Feelis';
import { FlexRow } from '../../styles/styled-components/rows';

type MeasurementRowProps = {
	temperature: number | undefined | null;
	co2Score: number | undefined | null;
};
export function MeasurementRow({ temperature, co2Score }: MeasurementRowProps) {
	if (!temperature && !co2Score) return null;
	return (
		<FlexRow
			style={{
				justifyContent: 'space-between',
				flex: 1,
				gap: '0.75rem'
			}}>
			{temperature && <MobileTempTile data={temperature} hasBorder />}
			{co2Score && <ScoreTile type={ScoreType.VENTILATION} data={co2Score} isDashboard />}
		</FlexRow>
	);
}
