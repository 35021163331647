import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ReactComponent as TemperatureIcon } from '../../../images/temp.svg';
import { ActivePalette } from '../../../models/context/Session';
import { useSession } from '../../../stores/SessionStore';
import { freesiColors, solColors } from '../../../styles/globalStyles';
import { MobileTemperatureContainer } from '../../../styles/styled-components/containers';
import { FlexRow } from '../../../styles/styled-components/rows';
import { MobileBlockLabel, MobileMeasurementValue } from '../../../styles/styled-components/text';

interface TileProps {
	data: number;
	hasBorder?: boolean;
}

export function MobileTempTile({ data, hasBorder }: TileProps) {
	const { t } = useTypedTranslation();
	const { state } = useSession();

	return (
		<MobileTemperatureContainer style={{ borderRadius: hasBorder ? '0.5rem' : 0 }} palette={state.activePalette}>
			<FlexRow
				style={{
					alignItems: 'start',
					height: '15%'
				}}>
				<TemperatureIcon
					fill={state.activePalette === ActivePalette.SOL ? solColors.solDarkYellow : freesiColors.iisyPurple}
					height="3.5rem"
				/>
			</FlexRow>
			<FlexRow style={{ justifyContent: 'center', alignItems: 'center', height: '70%' }}>
				<MobileMeasurementValue type={'measurementTemp'} isDesktop={state.isDesktop} palette={state.activePalette}>
					{`${data.toFixed(1)}°C`}
				</MobileMeasurementValue>
			</FlexRow>
			<MobileBlockLabel
				palette={state.activePalette}
				style={{ color: state.activePalette === 'SOL' ? solColors.solLightBlack : freesiColors.iisyGreen }}>
				{t('core.indoor-temp')}
			</MobileBlockLabel>
		</MobileTemperatureContainer>
	);
}
