import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

export function useWindowResize() {
	const [windowDimensions, setWindowDimensions] = useState({
		windowWidth: window.innerWidth,
		windowHeight: window.innerHeight
	});

	const handleResize = () => {
		setWindowDimensions({
			windowWidth: window.innerWidth,
			windowHeight: window.innerHeight
		});
	};

	useEffect(() => {
		const debouncedHandleResize = debounce(handleResize, 200);

		window.addEventListener('resize', debouncedHandleResize);

		return () => {
			window.removeEventListener('resize', debouncedHandleResize);
		};
	}, []);

	return windowDimensions;
}
