import { useApolloClient } from '@apollo/client';
import { Modal } from '@mui/material';
import { useCallback, useState } from 'react';
import { CHECK_PINCODE } from '../../graphql/queries';
import { InnerPinCodeContainer, PinCodeContainer } from '../../styles/styled-components/containers';
import { PinCodeModalText } from '../../styles/styled-components/text';
import { isNil } from '../../utils/typeGuards';
import { PinInputField } from './PinInputField';
import { WrongPin } from './WrongPin';

export type PinCodeBrand = {
	text: string;
	logo: string;
	altText: string;
};

type PinCodeModalProps = {
	brand: PinCodeBrand;
	serviceId: string;
	onPinCodeGiven: (pinCodeOk: boolean) => void;
};

export function PinCodeModal({ brand, serviceId, onPinCodeGiven }: PinCodeModalProps) {
	const [wrongPin, setWrongPin] = useState<boolean>(false);
	const [tries, setTries] = useState<number>(3);

	// Cannot use useLazyQuery due to it not being able to fire multiple queries. So use Apollo client directly.
	const client = useApolloClient();

	if (tries === 0) {
		onPinCodeGiven(false);
	}

	const showWrongPinCodeMessage = useCallback(
		(timeout: number = 3000) => {
			setWrongPin(true);
			setTimeout(() => {
				setWrongPin(false);
				setTries(tries - 1);
			}, timeout);
		},
		[tries]
	);

	const checkPinCode = useCallback(
		async (pinCode: string) => {
			const { data, error } = await client.query({
				query: CHECK_PINCODE,
				variables: {
					pin: pinCode,
					serviceId: serviceId
				}
			});
			if (!isNil(error)) {
				console.error(error);
				showWrongPinCodeMessage();
				return;
			}
			if (isNil(data)) {
				console.error('Pin code verification returned no data.');
				showWrongPinCodeMessage();
				return;
			}
			if (!data.pinCode) {
				showWrongPinCodeMessage();
				return;
			}
			onPinCodeGiven(true);
		},
		[serviceId, onPinCodeGiven, client, showWrongPinCodeMessage]
	);

	return (
		<Modal open={true} onBackdropClick={() => onPinCodeGiven(false)}>
			<PinCodeContainer>
				<img src={brand.logo} style={{ height: 100, width: 100 }} alt={brand.altText} />
				<PinCodeModalText>{brand.text}</PinCodeModalText>
				{wrongPin ? (
					<InnerPinCodeContainer>
						<WrongPin />
					</InnerPinCodeContainer>
				) : (
					<InnerPinCodeContainer>
						<PinInputField onComplete={checkPinCode} />
					</InnerPinCodeContainer>
				)}
			</PinCodeContainer>
		</Modal>
	);
}
