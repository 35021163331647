import { QuestionTile } from '../../components/tile/question/QuestionTile';
import { useWithFallbackContent } from '../../hooks/useWithFallbackContent';
import { useSession } from '../../stores/SessionStore';
import { QuestionnaireContainer } from '../../styles/styled-components/containers';
import { QuestionnaireTitle } from '../../styles/styled-components/text';

export function QuestionnaireView() {
	const { state } = useSession();
	const withFallback = useWithFallbackContent();

	const bgChooser = (index: number, palette: string) => {
		switch (palette) {
			case 'FREESI':
				return index % 2 === 0 ? '#DEFDF2' : '#f5f6ff';
			case 'SOL':
				return index % 2 === 0 ? '#FFC72C' : '#FCEBC2';
			default:
				return index % 2 === 0 ? '#DEFDF2' : '#f5f6ff';
		}
	};

	return (
		<QuestionnaireContainer>
			<QuestionnaireTitle palette={state.activePalette}>{withFallback(state.questionnaire?.title)}</QuestionnaireTitle>
			{state.questionnaire?.questions.map((question, index) => {
				return (
					<QuestionTile
						key={index}
						background={bgChooser(index, state.activePalette)}
						question={question}
						hasBorders={false}
					/>
				);
			})}
		</QuestionnaireContainer>
	);
}
