import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { SEND_ANSWER } from '../graphql/mutations';
import { QrCode, Question, TempQrCode } from '../models/queries/QueryResponses';
import { setQuestionsAnswered, useSession } from '../stores/SessionStore';
import { isQrCode } from '../utils/typeGuards';

export function useSendAnswer(
	question: Question,
	qrcode: QrCode | TempQrCode | undefined,
	premiseId: string | undefined,
	questionsAnswered: string[]
) {
	const { dispatch } = useSession();

	const [sendAnswer] = useMutation<{ answerSavedResponse: string }>(SEND_ANSWER);
	const [answerSent, setAnswerSent] = useState<boolean>(questionsAnswered.some(qId => qId === question.id));

	async function handleAnswerSent(value: number) {
		setAnswerSent(true);
		if (question && isQrCode(qrcode)) {
			await sendAnswer({
				variables: {
					premiseId: premiseId,
					questionId: question.id,
					qrCodeId: qrcode.id,
					value: value,
					sessionId: window.sessionStorage.getItem('session')
				}
			});
			dispatch(setQuestionsAnswered(questionsAnswered.concat(question.id)));
		}
	}

	return { handleAnswerSent, answerSent };
}
