import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { NotificationBlock } from '../../../styles/styled-components/containers';

interface TileProps {
	onHide: () => void;
}

export function NotificationTile({ onHide }: TileProps) {
	const { t } = useTypedTranslation();
	return (
		<NotificationBlock>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span style={{ fontSize: '12px', fontWeight: 500, color: '#00195A' }}>{t('core.notification')}</span>
				<IconButton sx={{ padding: 0 }} onClick={onHide}>
					<CloseIcon />
				</IconButton>
			</div>
			<span style={{ fontSize: '11px', fontWeight: 400, color: '#00195A', textAlign: 'center' }}>
				TÄHÄN TULEE ILMOITUKSIA FREESILTÄ
			</span>
		</NotificationBlock>
	);
}
