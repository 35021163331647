import { createTheme } from '@mui/material/styles';
import { blue } from '@mui/material/colors';

export default createTheme({
	palette: {
		primary: {
			main: '#00195F'
		},
		secondary: blue,

		SOL: {
			main: '#FCEBC2',
			contrastText: '#262122'
		}
	},
	typography: {
		fontFamily: 'Poppins'
	}
});

declare module '@mui/material/styles' {
	interface Palette {
		SOL: Palette['primary'];
	}

	interface PaletteOptions {
		SOL?: PaletteOptions['primary'];
	}
}

declare module '@mui/material/Button' {
	interface ButtonPropsColorOverrides {
		SOL: true;
	}
}
