import { Button } from '@mui/material';
import styled from 'styled-components';
import { PaletteProps } from '../interfaces';

/* <---- BUTTONS */
export const ToolbarButton = styled(Button)`
	background-color: #0e1860;
	border: 0;
	color: #fff;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	font-size: 1.2rem;

	&:disabled {
		color: #fff;
		opacity: 0.3;
	}

	&:hover {
		background-color: #0e1860;
		border-color: transparent;
	}
`;

export const CancelButton = styled(Button)`
	color: #363636;
	border-color: transparent;
	text-transform: uppercase;
	letter-spacing: 1.3px;
	background-color: #f5f5f8;
	font-size: 1.2rem;
	border: 0;

	&:hover {
		background-color: #f5f5f8;
		border-color: transparent;
	}
`;

export const ErrorViewButton = styled(Button)`
	width: 50%;
	background: #ff5a73;
`;

export const ServiceRequestButton = styled(Button)<PaletteProps>`
	background: ${props => (props.palette === 'SOL' ? '#FCEBC2' : '#00195F')};
	color: ${props => (props.palette === 'SOL' ? '#262122' : '#5BF7BF')};
	border-color: transparent;
	font-size: 12px;
	height: 42px;
	border-radius: 16px;
	&:hover {
		background: ${props => (props.palette === 'SOL' ? '#262122' : '#5BF7BF')};
		color: ${props => (props.palette === 'SOL' ? '#FCEBC2' : '#00195F')};
	}
`;
/* BUTTONS ----> */
