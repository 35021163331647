import { isEmpty } from 'lodash';
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ActivePalette } from '../../../models/context/Session';
import { MeasurementData, TimeSeriesObject } from '../../../models/queries/QueryResponses';
import { freesiColors, solColors } from '../../../styles/globalStyles';
import { renderLineAndBarGraphXLabel } from '../../../utils/helperFunctions';

type FlatBarChartProps = {
	data: MeasurementData;
	activePalette: ActivePalette;
};

export function ScoreBarChart({ data, activePalette }: FlatBarChartProps) {
	const { unsafeTranslation } = useTypedTranslation();

	const colorMap: { [key: number]: string } = {
		1: freesiColors.iisyRed,
		2: '#ffd846',
		3: freesiColors.iisyBlue,
		4: freesiColors.iisyGreen,
		5: freesiColors.iisyGreen
	};

	const ticks = Array.from({ length: 5 }, (_, i) => unsafeTranslation(`user-satisfaction.mostAnsweredValue.${i}`));

	const formatter = (value: number) =>
		value === 0 ? '' : unsafeTranslation(`user-satisfaction.mostAnsweredValue.${value - 1}`);

	if (!data.measurements || isEmpty(data.measurements)) return null;

	const measurementDataCopy = JSON.parse(JSON.stringify(data.measurements));
	const formattedBarData = measurementDataCopy.map((measurementObj: TimeSeriesObject) => ({
		...measurementObj,
		valueName: ticks[measurementObj.value === 0 ? measurementObj.value : measurementObj.value - 1]
	}));

	return (
		<ResponsiveContainer width="100%" minHeight={'20rem'}>
			<BarChart data={formattedBarData} margin={{ top: 25, right: 10, left: 20 }}>
				<CartesianGrid stroke={activePalette === 'SOL' ? '#64575a' : '#bfc2d5'} vertical={false} />
				<XAxis
					domain={['dataMin', 'dataMax']}
					tickCount={formattedBarData.length}
					tickLine={false}
					axisLine={false}
					tick={renderLineAndBarGraphXLabel}
					type="number"
					scale="time"
					interval={0}
					dataKey={'time'}
					padding={{ left: 15, right: 10 }}
				/>
				<YAxis
					domain={[1, 5]}
					ticks={[0, 1, 2, 3, 4, 5]}
					interval={0}
					tickFormatter={formatter}
					tick={{ fontSize: '1.3rem', fill: '#3E4550', dx: 15 }}
					tickLine={false}
					axisLine={false}
					width={90}
					orientation="right"
				/>
				<Bar isAnimationActive={false} name="ventilation" dataKey="value" barSize={30} radius={[5, 5, 0, 0]}>
					{formattedBarData.map((entry: any, index: any) => (
						<Cell
							key={`cell-${index}`}
							fill={activePalette === ActivePalette.SOL ? solColors.solRed : colorMap[entry.value]}
						/>
					))}
				</Bar>
			</BarChart>
		</ResponsiveContainer>
	);
}
