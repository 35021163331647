import { MotionValue, motion, useTransform } from 'framer-motion';
import { useTypedTranslation } from '../../hooks/useTypedTranslation';
import { EndTextContainer } from '../../styles/styled-components/containers';
import { EndText } from '../../styles/styled-components/text';
import { AnimatedContainer } from '../../views/install/AnimatedContainer';
import { CircularProgress } from './CircularProgress';

type SuccessContainerProps = {
	progress: MotionValue<number>;
};

export function SuccessContainer({ progress }: SuccessContainerProps) {
	const { t } = useTypedTranslation();

	const circleColor = useTransform(progress, [0, 95, 100], ['#FFCC66', '#FFCC66', '#66BB66']);
	const motionPath = 'M3 50L45 92L134 3';

	return (
		<EndTextContainer>
			<motion.div initial={{ x: 0 }} animate={{ x: 100 }} style={{ x: progress }} transition={{ duration: 1 }} />
			<CircularProgress progress={progress} circleColor={circleColor} strokeColor="#7BB86F" motionPath={motionPath} />
			<AnimatedContainer delay={1}>
				<EndText>{t('end.success')}</EndText>
				<EndText style={{ marginTop: '.5em' }}>{t('end.text')}</EndText>
			</AnimatedContainer>
		</EndTextContainer>
	);
}
