import { useTypedTranslation } from '../../../hooks/useTypedTranslation';
import { ActivePalette } from '../../../models/context/Session';
import { NoUserSatisfactionContainer } from '../../../styles/styled-components/containers';
import { SatisfactionCircle, SatisfactionInnerCircle } from '../../../styles/styled-components/misc';
import { SatisfactionTileText } from '../../../styles/styled-components/text';

type FeedbackTileProps = {
	activePalette: ActivePalette;
	isDesktop: boolean;
	lastNegFeedback: number;
};

export function FeedbackTile({ activePalette, isDesktop, lastNegFeedback }: FeedbackTileProps) {
	const { t } = useTypedTranslation();

	return (
		<NoUserSatisfactionContainer>
			<SatisfactionCircle palette={activePalette} mobile={!isDesktop}>
				<SatisfactionInnerCircle palette={activePalette}>{lastNegFeedback}</SatisfactionInnerCircle>
			</SatisfactionCircle>
			<SatisfactionTileText palette={activePalette}>{t('user-satisfaction.no-data-info')}</SatisfactionTileText>
		</NoUserSatisfactionContainer>
	);
}
