import { QrCode, TempQrCode } from '../models/queries/QueryResponses';

export const isQrCode = (code: QrCode | TempQrCode | undefined): code is QrCode => {
	return typeof code === 'object' && (code as QrCode).id !== undefined && (code as QrCode).identifier !== undefined;
};

/**
 * Checks if `object` is `null` or `undefined`.
 * @param object Object to check.
 * @returns True if `object` is `null` or `undefined`.
 */
export const isNil = (object: any): object is null | undefined => {
	return object === null || object === undefined;
};
