import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect, useState } from 'react';

type Props = {
	delay: number;
};

export function DelaySpinner({ delay }: Props) {
	const [showSpinner, setShowSpinner] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => setShowSpinner(true), delay);

		return () => {
			clearTimeout(timer);
		};
	}, [delay]);

	return showSpinner ? (
		<Box
			style={{
				position: 'absolute',
				top: 'calc(50% - 2.5em)',
				left: 'calc(50% - 2.5em)',
				pointerEvents: 'none',
				width: '5em',
				height: '5em'
			}}>
			<CircularProgress color="secondary" />
		</Box>
	) : null;
}
